/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState,useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Swal from 'sweetalert';
import axios from 'axios';

import jwtEncode from 'jwt-encode';
import { jwtDecode } from 'jwt-decode';

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);


const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

 const handleChangeEmail = (e) => {
  
   setEmail(e.target.value);
  };

   const handleChangePassword = (e) => {
   setPassword(e.target.value);
  };

 useEffect(() => {
//   const isLoggedIn = localStorage.getItem("LoginName");

//   if (!isLoggedIn && pathname !== "/authentication/sign-in") {
//     window.location.href = "/authentication/sign-in";
//   }

// }, [pathname]);



// const createToken = (userData) => {


//     const secretKey = "token"; // This should be kept safe and secure
//     const token = jwt.sign(userData, secretKey, { expiresIn: '1h' }); // Token expires in 1 hour
//     return token;




    const params = new URLSearchParams(window.location.search);
    var tokenFromUrl = params.get('token');
  if(params!='')
  {
    const decoded = jwtDecode(tokenFromUrl);
    if(decoded.exp < Math.floor(Date.now() / 1000)) 
    {
     
                       Swal({
            text: "Your session has expired.Please log in again",
            icon: "success",
            button: "OK",
          })  .then(() => {

          const url = new URL(window.location.href);
          const paramName = "token";
          url.searchParams.delete(paramName);
          window.location.href = url.toString();


          //       window.location.reload();
                   });


      return false;
    } 

    const clientData = {email:'',password:'',token:tokenFromUrl}
    console.log(clientData);
    console.log("ajay");
         axios.post(process.env.REACT_APP_BASE_URL+`authenticationAPI/Login`,clientData)
     .then(res => 
     {

console.log("res.data");
      console.log("kaja2");
      console.log(res.data);
          if(res.data.Status)  
          {
            localStorage.setItem("LoginName", res.data.LoginName);
            localStorage.setItem("LoginEmail", res.data.MemberID);
            localStorage.setItem("UserID", res.data.UserID);
            localStorage.setItem("Logo", res.data.Logo);
            localStorage.setItem("CustomerType", res.data.CustomerType);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("TempPasswordStatus", res.data.TempPasswordStatus);
            localStorage.setItem("Role", res.data.Role);
           // this.setState({redirect:true});   

            window.location.href='/dashboard';

          }
          else 

            Swal({
                
                text: res.data.Message,
                icon: 'error',
                button: 'OK',
              }) .then(() => {
                 // window.location.reload();
                   const url = new URL(window.location.href);
          const paramName = "token";
          url.searchParams.delete(paramName);
          window.location.href = url.toString();


                   });;
      })



}

// else
// {
//   alert("hello");
//   let url1 = process.env.REACT_APP_URL;

// window.location.href=url1.replace(/"/g, '');

// }







 });

  const userLogin = (e) => {
    e.preventDefault();

    if (email === "") {
      Swal({
        text: "Please enter email.",
        icon: "error",
        button: "OK",
      });
      return false;
    }

    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(email) === false) {
      Swal({
        text: "Please enter correct email.",
        icon: "error",
        button: "OK",
      });
      return false;
    }

    if (password === "") {
      Swal({
        text: "Please enter password.",
        icon: "error",
        button: "OK",
      });
      return false;
    }

    const clientData = { email: email, password: password,token:'' };
    console.log(clientData);

    console.log("ajay");
    console.log(process.env.REACT_APP_BASE_URL);

    setLoading(true);



      
      var token = jwtEncode({"email": email,"exp":Math.floor(Date.now() / 1000) + 3 * 60 * 60}, 'test');

  

 
//return false;
setTimeout(() => {
    axios 
      .post(process.env.REACT_APP_BASE_URL + `authenticationAPI/Login`, clientData)
      .then((res) => {
        setLoading(false);
        console.log(res.data);

//return false;
//console.log("kaja1");
        if (res.data.Status) {
        

          
          localStorage.setItem("LoginName", res.data.LoginName);
          localStorage.setItem("LoginEmail", res.data.MemberID);
          localStorage.setItem("UserID", res.data.UserID);
          localStorage.setItem("Logo", res.data.Logo);
         localStorage.setItem("CustomerType", res.data.CustomerType);
         localStorage.setItem("TempPasswordStatus", res.data.TempPasswordStatus);
         localStorage.setItem("Role", res.data.Role);
         
    
var tokenData = {token:token,user_member_id:res.data.MemberID};

axios 
      .post(process.env.REACT_APP_BASE_URL + `authenticationAPI/UpdateToken`, tokenData)
      .then((res) => {

 if (res.data.status) {

//alert(res.data.status);
 }

      });
   


   localStorage.setItem("token",token);

 setRedirect(true);

window.location.href='/dashboard';


        } else {
          Swal({
            text: res.data.Message,
            icon: "error",
            button: "OK",
          });
        }
      });

    },500);
  };


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }} style={{display:"none"}}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" value={email}  label="Email" fullWidth  onChange={handleChangeEmail} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" value={password}  label="Password" fullWidth  onChange={handleChangePassword} />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1} style={{display:"none"}}>
              <Switch   checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={userLogin}>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center" style={{display:"none"}}>
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
