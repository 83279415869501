import React, { Component } from 'react';
import Chart from 'chart.js/auto'; // Import chart.js (auto version)
import ChartDataLabels from 'chartjs-plugin-datalabels';


class PieChartComponent extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.chartInstance = null;
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.renderChart();
    }
  }

  componentWillUnmount() {
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }
  }

getDayName(dateString) {
    const date = new Date(dateString);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[date.getDay()];
  }
  renderChart() {
    const { data } = this.props;


console.log("naw");
console.log(data);
    // Extracting data for the last 7 days



    const last7DaysData = Object.values(data).slice(-7);
  

    const chartData = {
        labels: last7DaysData.map(item => `${this.getDayName(item.Period)} - ${item.CompleteCount}`),
      datasets: [
        {
          label: 'Day And Count',
          data: last7DaysData.map(item => item.CompleteCount),
          backgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#8C54FF',
            '#00CC99',
            '#FF9900',
            '#FF69B4',
          ],
          hoverBackgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#8C54FF',
            '#00CC99',
            '#FF9900',
            '#FF69B4',
          ],
        },
      ],
    };

//    const chartOptions = {}; // You can add chart options here if needed




  const chartOptions = {
      plugins: {

        // datalabels: {
        //   color: '#fff',
        //   formatter: (value, context) => {
        //     const label = context.chart.data.labels[context.dataIndex];
        //     return `${label}: ${value}`;
        //   },
        // },

 datalabels: {
          color: '#fff',
          anchor: 'end',
          align: 'start',
          offset: 10,
         
          backgroundColor: (context) => {
            return context.dataset.backgroundColor;
          },
          font: {
            weight: 'bold',
            size: 10,
          },
        


 formatter: (value, context) => {
            if (value > 0) {
              const label = context.chart.data.labels[context.dataIndex];
              return `${label}`;
            }
            return null; // Don't display the label if the value is 0 or less
          },


        },

        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.label || '';
              if (label) {
                label += ' ';
              }
              // if (context.raw !== null) {
              //   label += context.raw;
              // }
              return label;
            },
             title: function(context) {
              return context.map(item => item.raw).join(''); // Remove date from title
            }


          }
        }
      }
  };




    const chartRef = this.chartRef.current;
    if (chartRef) {
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }
      this.chartInstance = new Chart(chartRef, {
        type: 'pie',
        data: chartData,
        options: chartOptions,
        plugins: [ChartDataLabels],

      });
    }
  }

  render() {
   


  const customerType = localStorage.getItem('CustomerType');

    // Conditionally render the canvas only if Customer_Type is 'Dealer'
    if (customerType === 'Dealer') {
      return <canvas ref={this.chartRef} id="myPieChartCanvas" />;
    } else {
      return null; // Render nothing if Customer_Type is not 'Dealer'
    }



  }
}

export default PieChartComponent;
