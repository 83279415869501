import React, { useState } from 'react';
import axios from 'axios';
import { DropzoneArea } from 'material-ui-dropzone';
import Swal from 'sweetalert';
import { signPDFLive, signPDFEmail } from './signPDF'; // Ensure the path is correct
import SignaturePad from './SignaturePad';

const MyDropZone = (props) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [signedPdf, setSignedPdf] = useState(null);
  const [userName, setUserName] = useState('');

  const handleSign = async (method) => {
    if (files.length > 0 && userName) {
      setLoading(true);
      try {
        const file = files[0];
        let signedPdfBlob;
        if (method === 'live') {
          signedPdfBlob = await signPDFLive(file, userName);
        } else if (method === 'email') {
          signedPdfBlob = await signPDFEmail(file, userName);
        }

        setSignedPdf(signedPdfBlob);
         // Create a Blob URL and set it as the source for an image
        const blobUrl = URL.createObjectURL(signedPdfBlob);
        setImageUrl(blobUrl);

        const url12 = process.env.REACT_APP_BASE_URL + 'authenticationAPI/uploadupdatesignedaddendumsfile';
        const formData = new FormData();

        // Append signed PDF blob with a proper filename
        formData.append('pdf', signedPdfBlob, 'signed.pdf');

        formData.append('quoteid', props.quoteid);

        const response = await axios.post(url12, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.status === 200) {
          Swal({
            text: "Signed Addendums Pdf Has been Uploaded on AWS",
            icon: "success",
            button: "OK",
          });
          setFiles([...files, response.data.url]);
        } else {
          Swal({
            text: "File not uploaded",
            icon: "error",
            button: "OK",
          });
        }
      } catch (error) {
        console.error('Error signing or uploading the PDF:', error);
        Swal({
          text: "An error occurred",
          icon: "error",
          button: "OK",
        });
      } finally {
        setLoading(false);
      }
    } else {
      Swal({
        text: "Please select a file and enter your name.",
        icon: "warning",
        button: "OK",
      });
    }
  };

  const handleDrop = (droppedFiles) => {
    if (droppedFiles.length > 0) {
      setFiles(droppedFiles);
    }
  };

  return (
    <div>
      {loading && (
        <>
          <div
            style={{
              color: "black",
              position: "fixed",
              top: "15%",
              left: "0",
              fontSize: "22px",
              width: "100%",
              height: "100%",
              zIndex: "9999",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Loading...
          </div>
          <div className="loader-overlay">
            <div className="spinner-square">
              <div className="square-1 square"></div>
              <div className="square-2 square"></div>
              <div className="square-3 square"></div>
            </div>
          </div>
        </>
      )}
      <DropzoneArea
        onChange={handleDrop}
        acceptedFiles={['application/pdf']}
        maxFileSize={5000000} // 5 MB
        filesLimit={10}
        showPreviews={true}
        showPreviewsInDropzone={false}
        useChipsForPreview={true}
        dropzoneText=""
      />
     
      
      <button class={'signbtn'} style={{marginTop:"5px"}} onClick={() => handleSign('live')}>Live Signature</button>
      <button class={'signbtn'} onClick={() => handleSign('email')}>Email Signature</button>
      
      <div style={{"width":"100%","marginTop":"0px"}}>
        <SignaturePad setSignature={setUserName} />
      </div>
      {signedPdf && (
        <div style={{display:"none"}}>
          <a href={URL.createObjectURL(signedPdf)} download="signed.pdf">Download Signed PDF</a>
        </div>
      )}
 
       {localStorage.getItem("signedattach") && (
        <div style={{display:"none"}}>
          <img src={localStorage.getItem("signedattach")} alt="Signed PDF Preview" style={{ maxWidth: '100%', marginTop: '20px' }} />
        </div>
      )}
    </div>
  );
};

export default MyDropZone;
