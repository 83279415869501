/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import React, { useState, useEffect } from 'react'; 
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import axios from "axios";
import Swal from 'sweetalert2'
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';


import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  btn: {
    // Customize your button style here
    fontSize:'0.8rem',
    padding:'0.3rem 0.6rem',
    backgroundColor: '#f50057',
    color: 'white',
    '&:hover': {
      backgroundColor: '#c51162',
    },
  },
});


//const fs = require('fs');
let fs;
if (typeof window === 'undefined') {
    fs = require('fs');
}

import html2pdf from 'html2pdf.js';


function handleClickDelete(quote_id,delete_status)
{


var  quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/deletependingloan';


 const data = {
      quote_id: quote_id,
      status_value: parseInt(delete_status),
    };

      const response =  axios.post(quoteurl, data);
     
        if(response.status)
        {
        

 Swal({
      text: `Pending Loan Has been Removed id is:`+response.quoteid,
      icon: "success",
    });


}








}


async function addTextToPage(page, texts, font) {
  for (const { text, x, y, size } of texts) {
    page.drawText(text, {
      x: x,
      y: y,
      size: size,
      font: font,
      color: rgb(0, 0, 0),
    });
  }
}

async function addAgreementInfo(borrowerName, borrowerAddress, borrowerAddress2, borrowerCity, borrowerState, borrowerZip, coBorrowerName, coBorrowerAddress,coborrowerAddress2, coborrowerCity, coborrowerState, coborrowerZip, creditorName, creditorAddress, quoteNumber, year, make, model, style, vin,terms, contractStartDate, finalSchedulePayment, finalSchedulePaymentDate, residualPayment, quotestatus,memberid,pifrate,permonthpifamount) {
  


const monthpifamo=permonthpifamount !== undefined &&
    permonthpifamount > 0 ? (
      (() => {
        const numbersAndDecimal = permonthpifamount.toString().replace(/[^0-9.]/g, ''); // Keep only digits and dot
        const sections = numbersAndDecimal.split('.'); // Split at the dot, if present
        const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas for thousand separators
        const decimalPart = sections[1] ? sections[1].substring(0, 2) : ''; // Allow up to 4 decimal places
        const formattedValue = sections.length === 2
          ? `${integerPart}.${decimalPart}`
          : integerPart;
        return `$${formattedValue}`;
      })()
    ):'';



  const filePath = window.location.origin+'/PIFAddendum.pdf';




  const response = await fetch(filePath);
  const arrayBuffer = await response.arrayBuffer();
  const pdfDoc = await PDFDocument.load(arrayBuffer);
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const page = pdfDoc.getPages()[0];


console.log("borrowerAddress");

console.log(borrowerAddress);


  const borrowerInfo = [
  
 { text: String(borrowerName), x: 36, y: 727, size: 8 },
    { text: String(borrowerAddress), x: 36, y: 717, size: 7},
    { text: String(borrowerAddress2), x: 36, y: 709, size: 7},
    { text: String(borrowerCity+","+borrowerState+" "+borrowerZip), x: 36, y: 701, size: 7},
    
    { text: String(coBorrowerName), x: 206, y: 727, size: 8 },
    { text: String(coBorrowerAddress), x: 206, y: 717, size: 7},

  { text: String(coborrowerAddress2), x: 206, y: 709, size: 7},
    { text: String(coborrowerCity+","+coborrowerState+" "+coborrowerZip), x: 206, y: 701, size: 7},

   
    
    { text: String(creditorName), x: 408, y: 720, size: 8 },
    { text: String(creditorAddress), x: 408, y: 705, size: 7},



    { text: String(quoteNumber), x: 460, y: 758, size: 8 },
    { text: String(year), x: 50, y: 650, size: 8 },
    { text: String(make), x: 96, y: 650, size: 8 },
    { text: String(model), x: 210, y: 650, size: 8 },
    { text: String(style), x: 320, y: 650, size: 8 },
    { text: String(vin), x: 470, y: 650, size: 8 },

 { text: String(terms), x: 125, y: 624, size: 8 },


   { text: String(pifrate), x: 220, y: 624, size: 8 }, 
  { text: String(finalSchedulePayment), x: 340, y: 624, size: 8 }, 
  
     { text: String(monthpifamo), x: 490, y: 624, size: 8 }, 
  
   

    { text: String(contractStartDate), x: 50, y: 570, size: 8 },
   { text: residualPayment, x: 170, y: 570, size: 8 },
    { text: String(finalSchedulePaymentDate), x: 300, y: 570, size: 8 },
    { text: residualPayment, x: 450, y: 570, size: 8 }
  ];

  await addTextToPage(page, borrowerInfo, font);

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;


var status='';
status=quotestatus===0?'pending':'accepted'

  a.download = 'vehicle_loan_agreement_'+status+' '+'('+quoteNumber+')'+'.pdf';
  a.click();

var quoteData={quote_id:quoteNumber,status:2}
 axios 
      .post(process.env.REACT_APP_BASE_URL + `authenticationAPI/UpdateQuoteStatus`, quoteData)
      .then((res) => {
if(res.data.status)
{



    Swal.fire({
      text: "Status has been changed.",
      icon: "success",
      button: "OK"
    });


var quotememberData={user_member_id:memberid}

 axios 
      .post(process.env.REACT_APP_BASE_URL + `authenticationAPI/GetAdminEmailByMemberID`, quotememberData)
      .then((res) => {
if(res.data)
{

console.log(res.data);
// return false;

if(res.data)
{
           res.data.forEach(function(emailitem) 
           {




                                  const API_KEY12 = process.env.REACT_APP_EMAIL_API_KEY;
                                   var API_KEY= API_KEY12.replace(/"/g, '');

                                  
                                   
                                  const MESSAGE = {
                                    "key": API_KEY,
                                    "message": {
                                      "text": "Deal is Moved to Close.Deal id:-"+quoteNumber,
                                      "subject": "Deal Status Update",
                                      "from_email": "pifapp@myautoline.com",
                                          "to": [
                                        {
                                          "email": emailitem.Email,
                                          "name": "PIF",
                                          "type": "to"
                                        }
                                      ]
                                    }
                                  };

                                // Mandrill API endpoint
                                const MANDRILL_URL12 = process.env.REACT_APP_MANDRILL_API_URL;
                                var MANDRILL_URL= MANDRILL_URL12.replace(/"/g, '');


                                // Send email
                                axios.post(MANDRILL_URL, MESSAGE)
                                  .then(response => {
                                    console.log('Email sent successfully:', response.data);
                                  })
                                  .catch(error => {
                                    console.error('Error sending email:', error);
                                  });

          });
}


}

});

}



      });



}


async function addAgreementInfoCoupon(dealerName,terms,date,year,make,model,salesRep,signConfirm,receiveAmazonStatus,credit,customerName,customeEmail,cell,branch,cuReferral) {

  
console.log("my name is coupon");
  console.log(terms);


  const filePath = window.location.origin+'/image.pdf';




  const response = await fetch(filePath);
  const arrayBuffer = await response.arrayBuffer();
  const pdfDoc = await PDFDocument.load(arrayBuffer);
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const page = pdfDoc.getPages()[0];
  const borrowerInfo = [
   { text: String(dealerName), x: 220, y: 150, size: 8 },
    { text: String(terms), x: 320, y: 170, size: 8},
    { text: String(date), x: 510, y: 152, size: 8 },
    { text: String(year), x: 210, y: 130, size: 8},
    { text: String(make), x: 290, y: 130, size: 8 },
    { text: String(model), x: 340, y: 130, size: 8},
    { text: String(salesRep), x: 280, y: 115, size: 8 },
    { text: String(signConfirm), x: 420, y: 115, size: 8 },
    
receiveAmazonStatus==0?
    { text: String("."), x: 383, y: 85, size: 80 }:{ text: String('.'), x: 423, y: 85, size: 80 },

    { text: String(credit), x: 520, y: 70, size: 8 },
    { text: String(customerName), x: 260, y: 70, size: 8 },
    { text: String(customeEmail), x: 360, y: 50, size: 8 },
    { text: String(cell), x: 240, y: 50, size: 8 },
    { text: String(branch), x: 340, y: 30, size: 8 },
    { text: String(cuReferral), x: 480, y: 30, size: 8 },
  ];

  await addTextToPage(page, borrowerInfo, font);

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;




  a.download = 'coupon.pdf';
  a.click();

}


const handleQuoteStatusClick= (quote_id,quote_status,member_id)=>
{
// alert(quote_status)

 // Swal({
 //        text: "Please enter email.",
 //        icon: "error",
 //        button: "OK",
 //      });

Swal.fire({
  text: "Do you want to change the status from accepted to completed?",
  icon: "question",
  showCancelButton: true,
  confirmButtonText: "Yes",
  cancelButtonText: "No"
}).then((result) => {
  if (result.isConfirmed) {
    // Logic to change the status from accepted to completed
    console.log("Hello");

var quoteData={quote_id:quote_id,status:2}

 axios 
      .post(process.env.REACT_APP_BASE_URL + `authenticationAPI/UpdateQuoteStatus`, quoteData)
      .then((res) => {
if(res.data.status)
{

var quotememberData={user_member_id:member_id}

 axios 
      .post(process.env.REACT_APP_BASE_URL + `authenticationAPI/GetAdminEmailByMemberID`, quotememberData)
      .then((res) => {
if(res.data)
{

console.log("admin email");
console.log(res.data);
// return false;

 res.data.forEach(function(emailitem) {

    Swal.fire({
      text: "Status has been changed.",
      icon: "success",
      button: "OK"
    });


const API_KEY12 = process.env.REACT_APP_EMAIL_API_KEY;
 var API_KEY= API_KEY12.replace(/"/g, '');


const MESSAGE = {
  "key": API_KEY,
  "message": {
    "text": "Deal is Moved to Close.Deal id:-"+quote_id,
    "subject": "Deal Status Update",
    "from_email": "pifapp@myautoline.com",
        "to": [
      {
        "email": emailitem.Email,
        "name": "PIF",
        "type": "to"
      }
    ]
  }
};

// Mandrill API endpoint
const MANDRILL_URL12 = process.env.REACT_APP_MANDRILL_API_URL;
console.log(MANDRILL_URL);
 var MANDRILL_URL= MANDRILL_URL12.replace(/"/g, '');
// Send email
axios.post(MANDRILL_URL, MESSAGE)
  .then(response => {
    console.log('Email sent successfully:', response.data);
  })
  .catch(error => {
    console.error('Error sending email:', error.response.data);
  });


});

}
});



}


      });



  } else if (result.dismiss === Swal.DismissReason.cancel) {
    // Show another Swal when the user clicks "No"
    Swal.fire({
      text: "Status change was cancelled.",
      icon: "info",
      button: "OK"
    });
  }
});




}

// Helper function to prepare the arguments for handleClick
const prepareHandleClickArguments = (item) => {
  const firstNames = item.FirstNames || '';
  const addresses = item.Addresses || '';
  const address2 = item.Address2 || '';
  const city = item.City || '';
  const state = item.State || '';
const zip = item.Zip || '';


  const firstNamePart1 = firstNames.includes(',') ? firstNames.split(',')[0] : firstNames;
  const firstNamePart2 = firstNames.includes(',') ? firstNames.split(',')[1] : '';

  const addressPart1 = addresses.includes('!@#') ? addresses.split('!@#')[0] : addresses;
  const addressPart2 = addresses.includes('!@#') ? addresses.split('!@#')[1] : '';

   const addressPart21 = address2.includes(',') ? address2.split(',')[0] : address2;
  const addressPart22 = address2.includes(',') ? address2.split(',')[1] : '';

  const city21 = city.includes(',') ? city.split(',')[0] : city;
  const city22 = city.includes(',') ? city.split(',')[1] : '';

   const state21 = state.includes(',') ? state.split(',')[0] : state;
  const state22 = state.includes(',') ? state.split(',')[1] : '';


const zip21 = zip.includes(',') ? zip.split(',')[0] : zip;
  const zip22 = zip.includes(',') ? zip.split(',')[1] : '';

  return [
     firstNamePart1,
    addressPart1,
    addressPart21,
    city21,
    state21,
    zip21,
    firstNamePart2,
    addressPart2,
     addressPart22,
    city22,
    state22,
    zip22,
    'Century Federal',
    '',
    item.Terms,
    commaformatted(item.TotalFinancedAmount),
    commaformatted(item.ResidualValue),
    '',
    item.Year,
    item.CarName,
    item.Model,
    item.RviSegment,
    item.VinNumber,
    item.ApplicationID,
    item.QuoteStatus,
    item.UserMemberID,

    item.PifRate,
    item.PerMonthPifAmount

  ];
};



const prepareHandleClickArgumentsCoupon = (item) => {



  const firstNames = item.FirstNames || '';
  const firstNamePart1 = firstNames.includes(',') ? firstNames.split(',')[0] : firstNames;
 
  const emailAddresses = item.EmailAddresses || '';
  const emailAddressesPart1 = emailAddresses.includes(',') ? emailAddresses.split(',')[0] : emailAddresses;
 
 const phone = item.Phone || '';
  const phonePart1 = phone.includes(',') ? phone.split(',')[0] : phone;
 




  return [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '1',
    item.ApplicationID,
    firstNamePart1,
    emailAddressesPart1,
    phonePart1,
    item.Branch,
    item.CuReferral,
    item.UserMemberEmail
    

   ];
}


 const handleClick = (param1,param2,param21,param212,param213,param214,param3,param4,param41,param412,param413,param414,param5,param6,param7,param8,param9,param10,param11,param12,param13,param14,param15,param16,param17,param18,param19,param20) => {



const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
const day = String(currentDate.getDate()).padStart(2, '0');

const formattedDate = `${month}-${day}-${year}`;


const futureDate = new Date(currentDate);
futureDate.setMonth(currentDate.getMonth() + param7); // Add 84 months (7 years) to the current date

// Get the year, month, and day of the future date
const futureYear = futureDate.getFullYear();
const futureMonth = String(futureDate.getMonth() + 1).padStart(2, '0');
const futureDay = String(futureDate.getDate()).padStart(2, '0');

const formattedFutureDate = `${futureMonth}-${futureDay}-${futureYear}`;







         const data = {
            firstApplicantName: param1,
            firstApplicantAddress: param2,
            firstApplicantAddress2: param21==undefined?'':param21,
            firstApplicantCity: param212,
            firstApplicantState: param213,
            firstApplicantZip: param214,
            secondApplicantName: param3==undefined?'':param3,
            secondApplicantAddress: param4==undefined?'':param4,

            secondApplicantAddress2: param41==undefined?'':param41,
            secondApplicantCity: param412,
            secondApplicantState: param413,
            secondApplicantZip: param414,

            sellerCreditorName: param5,
            sellerCreditorAddress: param6,
            finalScheduledPayment: param8 ? '$' + param8 : '',
            finalPaymentDate: formattedFutureDate,
            contractDate: formattedDate,
            residualAmount:param9 ? '$' + param9 : '',
            year: param11,
            make: param12,
            model: param13,
            style: param14,
            vin: param15,
            quoteNumber: param16==undefined?'':param16,
            quotestatus:param17,
            memberid:param18,
            terms: param7==undefined?'':param7,
            pifrate: param19==undefined?'':param19,
            permonthpifamount: param20==undefined?'':param20,
      


        };

console.log(data);

        generatePDF(data);


    };




 const handleDownloadCouponClick = (param1,param2,param3,param4,param5,param6,param7,param8,param9,param10,param11,param12,param13,param14,param15) => 
 {



         const data = {

          dealerName:param1,
          terms:param2,
          date:param3,
          year:param4,
          make:param5,
          model:param6,
          salesRep:param7,
          signConfirm:param8,
          receiveAmazonStatus:param9,
          credit:param10,
          customerName:param11,
          customeEmail:param12,
          cell:param13,
          branch:param14,
          cuReferral:param15
          

                  };


console.log("bhaisahab");
console.log(data);

        generateCouponPDF(data);


    };



function formatDate(dateString) {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because getMonth returns zero-based month
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
}
 const generatePDF = (data) => {
    // const content = getHTMLContent(data);
    // html2pdf().from(content).save('vehicle_loan_agreement.pdf');

console.log("testdata");

console.log(data);


addAgreementInfo(data.firstApplicantName,data.firstApplicantAddress,data.firstApplicantAddress2,data.firstApplicantCity,data.firstApplicantState,data.firstApplicantZip,data.secondApplicantName,data.secondApplicantAddress,data.secondApplicantAddress2,data.secondApplicantCity,data.secondApplicantState,data.secondApplicantZip,data.sellerCreditorName,data.sellerCreditorAddress,data.quoteNumber,data.year,data.make,data.model,data.style,data.vin,data.terms,data.contractDate,data.finalScheduledPayment,data.finalPaymentDate,data.residualAmount,data.quotestatus,data.memberid,data.pifrate,data.permonthpifamount)





  };

   const generateCouponPDF = (data) => {


console.log("aaaaajjjjjj");  
console.log(data);

addAgreementInfoCoupon(data.dealerName,data.terms,data.date,data.year,data.make,data.model,data.salesRep,data.signConfirm,data.receiveAmazonStatus,data.credit,data.customerName,data.customeEmail,data.cell,data.branch,data.cuReferral)


  };
    

const commaformatted =(data) =>
{
      if (data === null || data === undefined) return ''; // Handle null or undefined
    const dataStr = String(data);

    const numbersAndDecimal = dataStr.replace(/[^0-9.]/g, ""); // Keep only digits and dot
    const sections = numbersAndDecimal.split("."); // Split at the dot, if present
    const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousand separators
    const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places

    const formattedValue =
      sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
      return  formattedValue;
}

// Function to generate the HTML content
//   const getHTMLContent = (data) => {
//     return `
//       <html lang="en"><head>
//     <meta charset="UTF-8">
//     <meta name="viewport" content="width=device-width, initial-scale=1.0">
//     <title>Vehicle Loan Agreement</title>
//     <style>
//           body {
//             font-family: sans-serif;
//             padding: 20px;
//             font-size: 12px;
//             margin: 0;
//             color:black; 
//         }
//         .container {
//             display: flex;
//             flex-wrap: wrap;
           
//         }
//         .flex-item {
//             border: 1px solid black;
//             padding: 10px;
//             box-sizing: border-box;
//             text-align: left;
//             font-size: 12px;
//         }
//         .full-width {
//             flex-basis: 100%;
//         }
//         .half-width {
//             flex-basis: 50%;
//         }
//         .third-width {
//             flex-basis: 33.33%;
//         }

//          .third-width1 {
//             flex-basis: 20%;
//         }
//         .third-width2 {
//             flex-basis: 25%;
//         }
//         .third-width3 {
//             flex-basis: 22%;
//         }
//         .section {
//             margin-bottom: 10px;
//             width: 100%;
//         }
//         .bold {
//             font-weight: bold;
//         }
//         .header {
//             background-color: #d1d1d1;
//             font-weight: bold;
//             padding-left: 10px;
//         }
//         .signature-line {
//             margin-top: 20px;
//             font-style: italic;
//         }.right-align {
//             text-align: right;
//         }
//         .signature-line {
//             margin-top: 20px;
//             font-style: italic;
//         }
//         .header-row span {
//             margin-left: 20px;
//             font-weight: normal;
//         }
    
//         .section {
//             margin-bottom: 10px;
//         }
//         h1 {
//             font-size: 14px;
//             font-weight: bold;
//         }
//         p {
//             margin: 5px 0;
//         }
//         ul {
//             padding-left: 20px;
//             list-style-type: none;
//         }
//         ul li:before {
//             content: "• ";
//             padding-right: 5px;
//         }
//         .indent {
//             padding-left: 20px;
//         }
//         .bold {
//             font-weight: bold;
//         }
//         .underline {
//             text-decoration: underline;
//         }
//         .no-extra-padding {
//             padding-top: 8px; /* Specific top padding */
//             padding-bottom: 8px; /* Specific bottom padding */
//         }
//     </style>
// </head>
// <body>
//     <div id="wholePdfContent" style="padding: 20px;">
        
    
//     <p style="font-size:1rem;letter-spacing: 0.2;"><span class="bold">PAY IT FORWARD [PIF] ADDENDUM TO VEHICLE LOAN AGREEMENT:</span> <span>Quote Number: <span id='quoteId'>${data.quoteNumber}</span></p>
//      <div class="container">
//             <div class="third-width flex-item ">Borrower Name & Address</div>
//             <div class="third-width flex-item ">Co-Borrower (or Guarantor) Name & Address</div>
//              <div class="third-width flex-item ">Seller / Creditor: Name & Address</div>
//             <div class="third-width flex-item">${data.firstApplicantName}<br>${data.firstApplicantAddress}</div>
//             <div class="third-width flex-item">${data.secondApplicantName}<br>${data.secondApplicantAddress}</div>
//             <div class="third-width flex-item">${data.sellerCreditorName}<br>${data.sellerCreditorAddress}</div>
//         </div>

//         <!-- Seller Information -->
//         <div class="container" style="padding:5px;background-color:#d0cece;text-align: center;justify-content: center;">
//            Vehicle Description
            
//         </div>

//         <!-- Vehicle Information -->
//         <div class="container">
//             <div class="third-width1 flex-item header">Year</div>
//             <div class="third-width1 flex-item header">Make</div>
//             <div class="third-width1 flex-item header">Model</div>
//              <div class="third-width1 flex-item header">Style</div>
//             <div class="third-width1 flex-item header">VIN</div>
//             <div class="third-width1 flex-item">${data.year}</div>
//             <div class="third-width1 flex-item">${data.make}</div>
//             <div class="third-width1 flex-item">${data.model}</div>
           
            
//             <div class="third-width1 flex-item">${data.style}</div>
//             <div class="third-width1 flex-item">${data.vin}</div>
            
//         </div>


//   <div class="container" style="text-align: left;">
           
//             <div class="third-width3 flex-item header">Options</div>
//         </div>
//         <!-- Contract Details -->
//         <div class="container">
//             <div class="third-width2 flex-item header">Contract Date</div>
//             <div class="third-width2 flex-item header">Final Scheduled Payment</div>
//             <div class="third-width2 flex-item header">Final Payment Date</div>
//             <div class="third-width2 flex-item header">Residual Amount</div>
//             <div class="third-width2 flex-item"><p>Date of the original loan /
// finance agreement  <span style="visibility:hidden">test demo data</span></p>
// <p id="contractDate">${data.contractDate}
// </div>
//             <div class="third-width2 flex-item"><p>Final scheduled payment of the
// original loan / finance agreement</p> 
// <p id="finalScheduledPayment">${commaformatted(data.finalScheduledPayment)}</p>
// </p></div>
//             <div class="third-width2 flex-item"><p>Final scheduled payment date in
// original loan / finance agreement</p><p id="finalPaymentDate">${data.finalPaymentDate}</p></div>


// <div class="third-width2 flex-item"><p>This amount may be the same as or different
// from your Final Scheduled Payment</p><p id="residualAmount">${commaformatted(data.residualAmount)}</p></div>



            
            

//         </div>
//      <div class="section">
//         <p>This Addendum adds the following <span class="bold">Vehicle Balloon Loan Terms</span> to the Contract:</p>
//         <p>This PIF Addendum [hereinafter the <span class="bold">Addendum</span>] is part of the Contract. The terms <span class="bold">you &amp; your</span> refer to the Buyer &amp; Co-Buyer / Guarantor [if any] identified above. <span class="bold">We / us / our</span> refer to the <span class="bold">Seller/Creditor</span> identified above or any subsequent assignee of the Seller/Creditor.<span class="bold"> Administrator</span> refers to AutoLine Marketing Group LLC / 547 East Washington Street Chagrin Falls OH 44022.</p>
//         <p>The Contract requires you to make the Final Scheduled Payment on the Final Payment Date identified above. Because the Finance Charge disclosed in the Contract is calculated daily – the actual amount due on the Final Payment Date may be more or less than the Final Scheduled Payment depending on your payment history. See the Contract for more information.</p>
//     </div>

//     <div class="section bold">Borrower Options on or before the Final Payment Date [at which time the PIF loan matures] – Choose one:</div>
//     <ul>
//         <li><span class="bold">Sell:</span> Sell the vehicle &amp; retain any equity in it toward your next purchase.</li>
//         <li><span class="bold">Trade In:</span> Trade your vehicle in for a different one &amp; use any equity in the trade toward your new vehicle purchase.</li>
//         <li><span class="bold">Purchase:</span> Keep the car &amp; pay off the remaining loan balance on or before the PIF Final Payment Date.</li>
//         <li><span class="bold">Refinance:</span> Refinance the loan balance <span class="bold">[This is available to you at any time during the Contract term]</span>
//  </li>
//         <li><span class="bold">Turn in:</span> Turn the vehicle in at the originating dealer &amp; walk away. Requires <span class="bold"> Vehicle Inspection.</span> Notify Administrator you are electing this option at least thirty [30] calendar days before the Final Payment Date. You must deliver the Vehicle on or before the Final Payment Date to the location the Administrator designates.</li>
//     </ul>

//     <div class="section" style="
//     margin-left: 8%;
// ">
//         <div class="bold">Schedule the Vehicle Return Appointment with AutoLine to expedite the process:</div>
//         <p>Email or Call AutoLine: TurnIn@myautoline.com or 888.634.4704</p>
//         <p>Originating Dealer [or other approved licensed dealer] to obtain Odometer Statement &amp; perform vehicle inspection [including but not limited to all the following items]:</p>
        
//         <div class="bold">Vehicle Condition report</div>
// <ul>
//             <li>Exterior: Dings / dents / scrapes / &amp; or scratches</li>
//             <li>Interior: Stains / burns / tears / &amp; or rips</li>
//             <li>Cracks &amp; or pitting in windshield / sunroof / other windows / rear window</li>
//             <li>Tire wear</li>
//             <li>Missing &amp; or non-working equipment &amp; any other vehicle damage</li>
//         </ul>
//     </div>

//     <div class="section" style="
//     margin-left: 8%;
// ">
//         <span class="bold">Settlement: </span>Based on the Vehicle Condition Report – Borrower[s] &amp; or Guarantor remit settlement in full on any charges due at Vehicle Turn in to the Lender named herein.
//     </div>

// <br/><br/><br/><br/><br/>
    
// <div class="signature-line">Borrower 1 Signature: ________________________________</div>
//     <div class="signature-line">Borrower 1 Printed Name &amp; Date Signed: ________________________________</div>
//     <div class="signature-line">Borrower 2 or Guarantor Signature: ________________________________</div>
//     <div class="signature-line">Borrower 2 or Guarantor Printed Name &amp; Date Signed: ________________________________</div>
// </div>
//      <!-- Adding Vehicle Balloon Loan Terms -->
    

//     <!-- Signature Lines -->
    
    
// </body></html>
//     `;
//   };



export default function data() {

  const classes = useStyles();

const [rows, setRows] = useState([]); 
const [selectedApplicants, setSelectedApplicants] = useState([]);
const [data, setData] = useState({
    firstApplicantName: "John Doe",
    firstApplicantAddress: "123 Main St",
    secondApplicantName: "Jane Smith",
    secondApplicantAddress: "456 Elm St",
    sellerCreditorName: "ABC Company",
    sellerCreditorAddress: "789 Oak St",
    finalScheduledPayment: "$1000",
    finalPaymentDate: "2024-05-31",
    contractDate: "2024-04-01",
    residualAmount: "$500",
    year: "2022",
    make: "Toyota",
    model: "Camry",
    style: "Sedan",
    vin: "ABC123DEF456GHI789",
    quoteNumber: 20
  });



useEffect(() => {

    


     

      document.addEventListener('DOMContentLoaded', function() {

 const storedSelectedApplicants = JSON.parse(localStorage.getItem('selectedApplicants'));
    if (storedSelectedApplicants) {
      setSelectedApplicants(storedSelectedApplicants);
    }

    printDocument();
});



var quoteurl='';
// Check if window.location is defined
// if (window.location && window.location.href) {
     if (window.location.pathname==='/quote') 
    {
        console.log("not complete");
        
        if (localStorage.getItem("selectedOption")) {
          //alert(1);
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetQuoteDetails2?start_date=&end_date=&user_id=&user_member_id=' + localStorage.getItem("selectedOption");
        } else if (localStorage.getItem("selectedOptionCompany") && !localStorage.getItem("startDateRange")) {
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetQuoteDetails2?start_date=&end_date=&user_member_id=&user_id=' + localStorage.getItem("selectedOptionCompany");
        //alert(2);
        } 
        else if (!localStorage.getItem("selectedOptionCompany") && localStorage.getItem("startDateRange") && localStorage.getItem("endDateRange")) {
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetQuoteDetails2?user_member_id=&user_id&start_date='+localStorage.getItem("startDateRange")+'&end_date='+localStorage.getItem("endDateRange");
       //alert(3);
        }
        else if (localStorage.getItem("selectedOptionCompany")  &&  localStorage.getItem("startDateRange") && localStorage.getItem("endDateRange")) {
         
//alert(4);
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetQuoteDetails2?start_date='+localStorage.getItem("startDateRange")+'&end_date='+localStorage.getItem("endDateRange")+'&user_member_id=&user_id=' + localStorage.getItem("selectedOptionCompany");
        } else if (localStorage.getItem("UserID")) {
       
//alert(5);
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetQuoteDetails?user_member_id='+ localStorage.getItem("LoginEmail");
        }
    }
  



if(quoteurl!==''){
axios.get(quoteurl)



      .then(response => 
      {
  

console.log("API Response:", response.data);

// localStorage.setItem("VehicaleQuoteLength", response.data.length);

let oldestDate = null;
let newestDate = null;

 oldestDate = new Date(Math.min.apply(null, response.data.map(item => new Date(item.QuoteDate))));
newestDate = new Date(Math.max.apply(null, response.data.map(item => new Date(item.QuoteDate))));



console.log("oldestDate"+oldestDate);
console.log("NewDate"+newestDate);


localStorage.setItem("olderdate",oldestDate);
localStorage.setItem("newerdate",newestDate);

        // Transform API data to fit the table's row structure
        const newRows = response.data.map(item => (


        {


 quotedate : (
            <MDTypography style={{cursor:"default"}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
              {formatDate(item.QuoteDate)}
            </MDTypography>
          ),
          
          quoteid: item.ApplicationID,
           usermemberid: item.UserMemberID,
          branch:item.UBranch,

         preappr: item.QuoteStatus === 1 ? 'YES' : (item.PreApprovedStatus === 0 ? 'No' : 'YES'),

         coupon: item.QuoteStatus === 1 ? '' : '$100',

        //  companyname: item.CompanyName,
         
          name: item.FirstNames && item.FirstNames.includes(',') ? item.FirstNames.split(',')[0] : item.FirstNames,
          
        //  email: item.EmailAddresses && item.EmailAddresses.includes(',') ? item.EmailAddresses.split(',')[0] : item.EmailAddresses,
          vehicledetails: (
            <MDBox ml={-1}>
               {item.vehicleYear} {item.CarName} {item.Model}
            </MDBox>
          ),
          totalfundedprice: (
            <MDTypography style={{cursor:"default",display:"none"}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
              ${commaformatted(item.TotalFinancedAmount)}
            </MDTypography>
          ),
          terms: (
            <MDTypography style={{cursor:"default",display:"none"}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
              {item.Terms} months
            </MDTypography>
          ),
          monthlypaymentrate: (
            <MDTypography style={{cursor:"default",display:"none"}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
              {item.PifRate}
            </MDTypography>
          ),
          monthlypayment: (
            <MDTypography style={{cursor:"default",display:"none"}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
              ${commaformatted(item.PerMonthPifAmount)}
            </MDTypography>
          ),
          pdf: (  
            <MDTypography component="a" href={'javascript:void(0)'} variant="caption" color="text" fontWeight="medium">
          
      <MDBadge style={{cursor:"pointer"}} onClick={item.QuoteStatus === 1 ?() =>handleClick(...prepareHandleClickArguments(item)): null}   badgeContent="download" color="success" variant="gradient" size="sm" />
        
            </MDTypography>
          ),

            quotestatus: (
            <MDTypography component="a" href={'javascript:void(0)'} variant="caption" color="text" fontWeight="medium">
              <MDBadge style={{cursor:"pointer"}} onClick={item.QuoteStatus === 1 ? () => handleQuoteStatusClick(item.ApplicationID,item.QuoteStatus,item.UserMemberID) : null}  badgeContent={item.QuoteStatus==0?'pending':'accepted'} color={item.QuoteStatus==0?'pending':'success'} variant="gradient" size="sm" />
            </MDTypography>
          ),

           couponpdf: (
            <MDTypography component="a" href={'javascript:void(0)'} variant="caption" color="text" fontWeight="medium">
          
      <MDBadge style={{cursor:"pointer"}}  onClick={item.QuoteStatus === 0 ?() =>handleDownloadCouponClick(...prepareHandleClickArgumentsCoupon(item)): null}  badgeContent="download" color={item.QuoteStatus === 0 ?"success":"secondary"} variant="gradient" size="sm" />
        
            </MDTypography>
          ),
          action: (
            <MDTypography style={{cursor:"default"}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
           <input type="checkbox"  onChange={(e) => handleCheckboxChange(e, item.ApplicationID)}
              checked={selectedApplicants.includes(item.ApplicationID)}   name="exportRows"/>
             
             <Button 
      className={classes.btn}
      variant="contained" 
      startIcon={<DeleteIcon />}
   

onClick={(e) =>handleClickDelete(item.ApplicationID,1)}

    >
      Delete
    </Button>
            </MDTypography>
 
          ),
        }));
console.log(newRows);
        setRows(newRows);

      })

    }
}, [selectedApplicants]);


const handleCheckboxChange = (e, applicationID) => {
  const { checked } = e.target;
  setSelectedApplicants(prevSelectedApplicants => {
    let updatedSelectedApplicants;
    if (checked) {
      updatedSelectedApplicants = [...prevSelectedApplicants, applicationID];
    } else {
      updatedSelectedApplicants = prevSelectedApplicants.filter(id => id !== applicationID);
    }
    localStorage.setItem('selectedApplicants', JSON.stringify(updatedSelectedApplicants));
    return updatedSelectedApplicants;
  });

  console.log(selectedApplicants);
};

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
  <>
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography  display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description+"ajay"}</MDTypography>
    </MDBox>
    
</>
  );



  return {
    columns: [
     
 { Header: "member", accessor: "name", width: "45%", align: "left" },

       { Header: "date", accessor: "quotedate", align: "center" },

     { Header: "ref#", accessor: "quoteid", width: "45%", align: "left" },

      { Header: "teller#", accessor: "usermemberid", width: "45%", align: "left" },
      
{ Header: localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer"?"dealer loaction":"branch", accessor: "branch", width: "45%", align: "left" },
      
      { Header: "pre-appr", accessor: "preappr", width: "45%", align: "left" },

       { Header: "coupon", accessor: "coupon", width: "45%", align: "left" },

      //{ Header: "companyname", accessor: "companyname", width: "45%", align: "left" },
      
     
     // { Header: "email", accessor: "email", align: "left" },
     // { Header: "vehicle details", accessor: "vehicledetails", align: "center" },
     // { Header: "total funded price", accessor: "totalfundedprice", align: "center" },
    //{ Header: "terms", accessor: "terms", align: "center",show: false },
     // { Header: "monthly payment rate", accessor: "monthlypaymentrate", align: "center" },
     // { Header: "monthly payment", accessor: "monthlypayment", align: "center" },
       { Header: "addendum", accessor: "pdf", align: "center" },
        { Header: "quote status", accessor: "quotestatus", align: "center" },
          { Header: "couponpdf", accessor: "couponpdf", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: rows,
  };
}
