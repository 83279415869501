/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

function DataTableBodyCell({ index,noBorder, align, children }) {


let isHidden;
let isHiddenPending;
let isActionPending;


if(localStorage.getItem("CustomerType") === "Dealer"){

 isHidden = (index === 2 || index === 4 || index === 7 || index === 8 || index === 9|| index === 10 || index === 11 || index === 12 || index === 13|| index === 17 || index === 18)  && window.location.pathname=="/completedquote" && localStorage.getItem("CustomerType") === "Dealer";

isHiddenPending = (index === 3 || index === 9 || index === 5 || index === 6 || index === 8 || index === 17) && window.location.pathname=="/quote" && localStorage.getItem("CustomerType") === "Dealer";

 isActionPending = (index === 6 ) && window.location.pathname=="/customer" && localStorage.getItem("CustomerType") === "Dealer";


}

else if(localStorage.getItem("CustomerType") === "Credit Union") 
{

 isHidden = (index === 10 || index === 18 || index === 17)  && window.location.pathname=="/completedquote" && localStorage.getItem("CustomerType") === "Credit Union";

 isHiddenPending = (index === 10) && window.location.pathname=="/quote" && localStorage.getItem("CustomerType") === "Credit Union";

 isActionPending = (index === 6 ) && window.location.pathname=="/customer" && localStorage.getItem("CustomerType") === "Credit Union";


}

else
{

 
}


  return (
    <MDBox
      component="td"
      textAlign={align}
      py={1.5}
      px={3}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
      })}

   

className={`${isHidden || isHiddenPending ? "dealer-column-hidden" : ""} ${isActionPending ? "customer-column-hidden" : ""}`}


    >
      <MDBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        {children}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
