/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import React, { useState, useEffect } from 'react'; 
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MyDropZone2 from '../../MyDropZone'; 
import { MenuItem, Select } from '@mui/material';


// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import axios from "axios";

import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';

//const fs = require('fs');
let fs;
if (typeof window === 'undefined') {
    fs = require('fs');
}

import html2pdf from 'html2pdf.js';


async function addTextToPage(page, texts, font) {
  for (const { text, x, y, size } of texts) {
    page.drawText(text, {
      x: x,
      y: y,
      size: size,
      font: font,
      color: rgb(0, 0, 0),
    });
  }
}

async function addAgreementInfo(borrowerName, borrowerAddress, borrowerAddress2, borrowerCity, borrowerState, borrowerZip, coBorrowerName, coBorrowerAddress, coborrowerAddress2, coborrowerCity, coborrowerState, coborrowerZip, creditorName, creditorAddress, quoteNumber, year, make, model, style, vin,terms, contractStartDate, finalSchedulePayment, finalSchedulePaymentDate, residualPayment, quotestatus,pifrate,permonthpifamount)
 {
  

const monthpifamo=permonthpifamount !== undefined &&
    permonthpifamount > 0 ? (
      (() => {
        const numbersAndDecimal = permonthpifamount.toString().replace(/[^0-9.]/g, ''); // Keep only digits and dot
        const sections = numbersAndDecimal.split('.'); // Split at the dot, if present
        const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas for thousand separators
        const decimalPart = sections[1] ? sections[1].substring(0, 2) : ''; // Allow up to 4 decimal places
        const formattedValue = sections.length === 2
          ? `${integerPart}.${decimalPart}`
          : integerPart;
        return `$${formattedValue}`;
      })()
    ):'';

  
  const filePath = window.location.origin+'/PIFAddendum.pdf';
  const response = await fetch(filePath);
  const arrayBuffer = await response.arrayBuffer();
  const pdfDoc = await PDFDocument.load(arrayBuffer);
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const page = pdfDoc.getPages()[0];

console.log("borrowerAddress");
console.log(borrowerAddress);


  const borrowerInfo = [

 { text: String(borrowerName), x: 36, y: 727, size: 8 },
    { text: String(borrowerAddress), x: 36, y: 717, size: 7},
    { text: String(borrowerAddress2), x: 36, y: 709, size: 7},
    { text: String(borrowerCity+","+borrowerState+" "+borrowerZip), x: 36, y: 701, size: 7},
    { text: String(coBorrowerName), x: 206, y: 727, size: 8 },
    { text: String(coBorrowerAddress), x: 206, y: 717, size: 7},
    { text: String(coborrowerAddress2), x: 206, y: 709, size: 7},
    { text: String(coborrowerCity+","+coborrowerState+" "+coborrowerZip), x: 206, y: 701, size: 7},
    { text: String(creditorName), x: 408, y: 720, size: 8 },
    { text: String(creditorAddress), x: 408, y: 705, size: 7},
    { text: String(quoteNumber), x: 460, y: 758, size: 8 },
    { text: String(year), x: 50, y: 650, size: 8 },
    { text: String(make), x: 96, y: 650, size: 8 },
    { text: String(model), x: 210, y: 650, size: 8 },
    { text: String(style), x: 320, y: 650, size: 8 },
    { text: String(vin), x: 470, y: 650, size: 8 },

 { text: String(terms), x: 125, y: 624, size: 8 },


   { text: String(pifrate), x: 220, y: 624, size: 8 }, 
  { text: String(finalSchedulePayment), x: 340, y: 624, size: 8 }, 
  
     { text: String(monthpifamo), x: 490, y: 624, size: 8 }, 
  
   

    { text: String(contractStartDate), x: 50, y: 570, size: 8 },
   { text: residualPayment, x: 170, y: 570, size: 8 },
    { text: String(finalSchedulePaymentDate), x: 300, y: 570, size: 8 },
    { text: residualPayment, x: 450, y: 570, size: 8 }



  ];

  await addTextToPage(page, borrowerInfo, font);

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  

  var status='';
status=quotestatus===0?'pending':'accepted'

   a.download = 'vehicle_loan_agreement_'+status+' '+'('+quoteNumber+')'+'.pdf';
  a.click();

}

const handleSignedClick =(filename) =>
{
  alert(filename);


var  quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/downloadupdatesignedaddendumsfile?signedfilename='+filename;

axios({
    url: quoteurl,
    method: 'GET',
    responseType: 'blob',  // Important: This tells axios to download the binary content of the response
})
.then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = "downloaded.pdf";
    document.body.appendChild(a);
    a.click();
    a.remove();
})
.catch(e => console.error('Download error:', e));

  

}



 const handleClick = (param1,param2,param21,param212,param213,param214,param3,param4,param41,param412,param413,param414,param5,param6,param7,param8,param9,param10,param11,param12,param13,param14,param15,param16,param17,param18,param19) => {





const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
const day = String(currentDate.getDate()).padStart(2, '0');

const formattedDate = `${month}-${day}-${year}`;


const futureDate = new Date(currentDate);
futureDate.setMonth(currentDate.getMonth() + param7); // Add 84 months (7 years) to the current date

// Get the year, month, and day of the future date
const futureYear = futureDate.getFullYear();
const futureMonth = String(futureDate.getMonth() + 1).padStart(2, '0');
const futureDay = String(futureDate.getDate()).padStart(2, '0');

const formattedFutureDate = `${futureMonth}-${futureDay}-${futureYear}`;







         const data = {
            firstApplicantName: param1,
            firstApplicantAddress: param2,
            firstApplicantAddress2: param21==undefined?'':param21,
            firstApplicantCity: param212,
            firstApplicantState: param213,
            firstApplicantZip: param214,
            secondApplicantName: param3==undefined?'':param3,
            secondApplicantAddress: param4==undefined?'':param4,
             secondApplicantAddress2: param41==undefined?'':param41,
            secondApplicantCity: param412,
            secondApplicantState: param413,
            secondApplicantZip: param414,
         

            sellerCreditorName: param5,
            sellerCreditorAddress: param6,
            finalScheduledPayment: param8 ? '$' + param8 : '',
            finalPaymentDate: formattedFutureDate,
            contractDate: formattedDate,
            residualAmount:param9 ? '$' + param9 : '',
            year: param11,
            make: param12,
            model: param13,
            style: param14,
            vin: param15,
            quoteNumber: param16==undefined?'':param16,
            quotestatus: param17==undefined?'':param17,
            terms: param7==undefined?'':param7,
            pifrate: param18==undefined?'':param18,
            permonthpifamount: param19==undefined?'':param19,
        };


console.log("testadminpdf");
console.log(data);

        generatePDF(data);


    };


function formatDate(dateString) {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because getMonth returns zero-based month
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
}

function formatDate2(dateString, terms) {
    const date = new Date(dateString);
    let newDate = new Date(date); // Create a new date object to avoid mutating the original

    // Adjust date based on different terms values
    switch (terms) {
        case 12:
            newDate.setMonth(date.getMonth() + 12); // Add 12 months
            break;
        case 24:
            newDate.setMonth(date.getMonth() + 24); // Add 24 months
            break;
        case 36:
            newDate.setMonth(date.getMonth() + 36); // Add 36 months
            break;
        case 48:
            newDate.setMonth(date.getMonth() + 48); // Add 48 months
            break;
        case 60:
            newDate.setMonth(date.getMonth() + 60); // Add 60 months
            break;
        case 72:
            newDate.setMonth(date.getMonth() + 72); // Add 72 months
            break;
        case 84:
            newDate.setMonth(date.getMonth() + 84); // Add 84 months
            break;
        default:
            // Handle unexpected terms value (optional)
            break;
    }

    // Format the adjusted date
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const day = newDate.getDate().toString().padStart(2, '0');
    const year = newDate.getFullYear();

    return `${month}-${day}-${year}`;
}

 const generatePDF = (data) => {

addAgreementInfo(data.firstApplicantName,data.firstApplicantAddress,data.firstApplicantAddress2,data.firstApplicantCity,data.firstApplicantState,data.firstApplicantZip,data.secondApplicantName,data.secondApplicantAddress,data.secondApplicantAddress2,data.secondApplicantCity,data.secondApplicantState,data.secondApplicantZip,data.sellerCreditorName,data.sellerCreditorAddress,data.quoteNumber,data.year,data.make,data.model,data.style,data.vin,data.terms,data.contractDate,data.finalScheduledPayment,data.finalPaymentDate,data.residualAmount,data.quotestatus,data.pifrate,data.permonthpifamount)


  };
    
// Helper function to prepare the arguments for handleClick
const prepareHandleClickArguments = (item) => {
  const firstNames = item.FirstNames || '';
  const addresses = item.Addresses || '';
    const address2 = item.Address2 || '';
  const city = item.City || '';
  const state = item.State || '';
const zip = item.Zip || '';

  const firstNamePart1 = firstNames.includes(',') ? firstNames.split(',')[0] : firstNames;
  const firstNamePart2 = firstNames.includes(',') ? firstNames.split(',')[1] : '';

  const addressPart1 = addresses.includes('!@#') ? addresses.split('!@#')[0] : addresses;
  const addressPart2 = addresses.includes('!@#') ? addresses.split('!@#')[1] : '';

    const addressPart21 = address2.includes(',') ? address2.split(',')[0] : address2;
  const addressPart22 = address2.includes(',') ? address2.split(',')[1] : '';

  const city21 = city.includes(',') ? city.split(',')[0] : city;
  const city22 = city.includes(',') ? city.split(',')[1] : '';

   const state21 = state.includes(',') ? state.split(',')[0] : state;
  const state22 = state.includes(',') ? state.split(',')[1] : '';


const zip21 = zip.includes(',') ? zip.split(',')[0] : zip;
  const zip22 = zip.includes(',') ? zip.split(',')[1] : '';


  return [
    firstNamePart1,
    addressPart1,
    addressPart21,
    city21,
    state21,
    zip21,
    firstNamePart2,
    addressPart2,
     addressPart22,
    city22,
    state22,
    zip22,
    'Century Federal',
    '',
    item.Terms,
    commaformatted(item.TotalFinancedAmount),
    commaformatted(item.ResidualValue),
    '',
    item.Year,
    item.CarName,
    item.Model,
    item.RviSegment,
    item.VinNumber,
    item.ApplicationID,
    item.quotestatus,
    item.PifRate,
    item.PerMonthPifAmount
  ];
};



const commaformatted =(data) =>
{
      if (data === null || data === undefined) return ''; // Handle null or undefined
    const dataStr = String(data);

    const numbersAndDecimal = dataStr.replace(/[^0-9.]/g, ""); // Keep only digits and dot
    const sections = numbersAndDecimal.split("."); // Split at the dot, if present
    const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousand separators
    const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places

    const formattedValue =
      sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
      return  formattedValue;
}

// Function to generate the HTML content
//   const getHTMLContent = (data) => {
//     return `
//       <html lang="en"><head>
//     <meta charset="UTF-8">
//     <meta name="viewport" content="width=device-width, initial-scale=1.0">
//     <title>Vehicle Loan Agreement</title>
//     <style>
//           body {
//             font-family: sans-serif;
//             padding: 20px;
//             font-size: 12px;
//             margin: 0;
//             color:black; 
//         }
//         .container {
//             display: flex;
//             flex-wrap: wrap;
           
//         }
//         .flex-item {
//             border: 1px solid black;
//             padding: 10px;
//             box-sizing: border-box;
//             text-align: left;
//             font-size: 12px;
//         }
//         .full-width {
//             flex-basis: 100%;
//         }
//         .half-width {
//             flex-basis: 50%;
//         }
//         .third-width {
//             flex-basis: 33.33%;
//         }

//          .third-width1 {
//             flex-basis: 20%;
//         }
//         .third-width2 {
//             flex-basis: 25%;
//         }
//         .third-width3 {
//             flex-basis: 22%;
//         }
//         .section {
//             margin-bottom: 10px;
//             width: 100%;
//         }
//         .bold {
//             font-weight: bold;
//         }
//         .header {
//             background-color: #d1d1d1;
//             font-weight: bold;
//             padding-left: 10px;
//         }
//         .signature-line {
//             margin-top: 20px;
//             font-style: italic;
//         }.right-align {
//             text-align: right;
//         }
//         .signature-line {
//             margin-top: 20px;
//             font-style: italic;
//         }
//         .header-row span {
//             margin-left: 20px;
//             font-weight: normal;
//         }
    
//         .section {
//             margin-bottom: 10px;
//         }
//         h1 {
//             font-size: 14px;
//             font-weight: bold;
//         }
//         p {
//             margin: 5px 0;
//         }
//         ul {
//             padding-left: 20px;
//             list-style-type: none;
//         }
//         ul li:before {
//             content: "• ";
//             padding-right: 5px;
//         }
//         .indent {
//             padding-left: 20px;
//         }
//         .bold {
//             font-weight: bold;
//         }
//         .underline {
//             text-decoration: underline;
//         }
//         .no-extra-padding {
//             padding-top: 8px; /* Specific top padding */
//             padding-bottom: 8px; /* Specific bottom padding */
//         }
//     </style>
// </head>
// <body>
//     <div id="wholePdfContent" style="padding: 20px;">
        
    
//     <p style="font-size:1rem;letter-spacing: 0.2;"><span class="bold">PAY IT FORWARD [PIF] ADDENDUM TO VEHICLE LOAN AGREEMENT:</span> <span>Quote Number: <span id='quoteId'>${data.quoteNumber}</span></p>
//      <div class="container">
//             <div class="third-width flex-item ">Borrower Name & Address</div>
//             <div class="third-width flex-item ">Co-Borrower (or Guarantor) Name & Address</div>
//              <div class="third-width flex-item ">Seller / Creditor: Name & Address</div>
//             <div class="third-width flex-item">${data.firstApplicantName}<br>${data.firstApplicantAddress}</div>
//             <div class="third-width flex-item">${data.secondApplicantName}<br>${data.secondApplicantAddress}</div>
//             <div class="third-width flex-item">${data.sellerCreditorName}<br>${data.sellerCreditorAddress}</div>
//         </div>

//         <!-- Seller Information -->
//         <div class="container" style="padding:5px;background-color:#d0cece;text-align: center;justify-content: center;">
//            Vehicle Description
            
//         </div>

//         <!-- Vehicle Information -->
//         <div class="container">
//             <div class="third-width1 flex-item header">Year</div>
//             <div class="third-width1 flex-item header">Make</div>
//             <div class="third-width1 flex-item header">Model</div>
//              <div class="third-width1 flex-item header">Style</div>
//             <div class="third-width1 flex-item header">VIN</div>
//             <div class="third-width1 flex-item">${data.year}</div>
//             <div class="third-width1 flex-item">${data.make}</div>
//             <div class="third-width1 flex-item">${data.model}</div>
           
            
//             <div class="third-width1 flex-item">${data.style}</div>
//             <div class="third-width1 flex-item">${data.vin}</div>
            
//         </div>


//   <div class="container" style="text-align: left;">
           
//             <div class="third-width3 flex-item header">Options</div>
//         </div>
//         <!-- Contract Details -->
//         <div class="container">
//             <div class="third-width2 flex-item header">Contract Date</div>
//             <div class="third-width2 flex-item header">Final Scheduled Payment</div>
//             <div class="third-width2 flex-item header">Final Payment Date</div>
//             <div class="third-width2 flex-item header">Residual Amount</div>
//             <div class="third-width2 flex-item"><p>Date of the original loan /
// finance agreement  <span style="visibility:hidden">test demo data</span></p>
// <p id="contractDate">${data.contractDate}
// </div>
//             <div class="third-width2 flex-item"><p>Final scheduled payment of the
// original loan / finance agreement</p> 
// <p id="finalScheduledPayment">${commaformatted(data.finalScheduledPayment)}</p>
// </p></div>
//             <div class="third-width2 flex-item"><p>Final scheduled payment date in
// original loan / finance agreement</p><p id="finalPaymentDate">${data.finalPaymentDate}</p></div>


// <div class="third-width2 flex-item"><p>This amount may be the same as or different
// from your Final Scheduled Payment</p><p id="residualAmount">${commaformatted(data.residualAmount)}</p></div>



            
            

//         </div>
//      <div class="section">
//         <p>This Addendum adds the following <span class="bold">Vehicle Balloon Loan Terms</span> to the Contract:</p>
//         <p>This PIF Addendum [hereinafter the <span class="bold">Addendum</span>] is part of the Contract. The terms <span class="bold">you &amp; your</span> refer to the Buyer &amp; Co-Buyer / Guarantor [if any] identified above. <span class="bold">We / us / our</span> refer to the <span class="bold">Seller/Creditor</span> identified above or any subsequent assignee of the Seller/Creditor.<span class="bold"> Administrator</span> refers to AutoLine Marketing Group LLC / 547 East Washington Street Chagrin Falls OH 44022.</p>
//         <p>The Contract requires you to make the Final Scheduled Payment on the Final Payment Date identified above. Because the Finance Charge disclosed in the Contract is calculated daily – the actual amount due on the Final Payment Date may be more or less than the Final Scheduled Payment depending on your payment history. See the Contract for more information.</p>
//     </div>

//     <div class="section bold">Borrower Options on or before the Final Payment Date [at which time the PIF loan matures] – Choose one:</div>
//     <ul>
//         <li><span class="bold">Sell:</span> Sell the vehicle &amp; retain any equity in it toward your next purchase.</li>
//         <li><span class="bold">Trade In:</span> Trade your vehicle in for a different one &amp; use any equity in the trade toward your new vehicle purchase.</li>
//         <li><span class="bold">Purchase:</span> Keep the car &amp; pay off the remaining loan balance on or before the PIF Final Payment Date.</li>
//         <li><span class="bold">Refinance:</span> Refinance the loan balance <span class="bold">[This is available to you at any time during the Contract term]</span>
//  </li>
//         <li><span class="bold">Turn in:</span> Turn the vehicle in at the originating dealer &amp; walk away. Requires <span class="bold"> Vehicle Inspection.</span> Notify Administrator you are electing this option at least thirty [30] calendar days before the Final Payment Date. You must deliver the Vehicle on or before the Final Payment Date to the location the Administrator designates.</li>
//     </ul>

//     <div class="section" style="
//     margin-left: 8%;
// ">
//         <div class="bold">Schedule the Vehicle Return Appointment with AutoLine to expedite the process:</div>
//         <p>Email or Call AutoLine: TurnIn@myautoline.com or 888.634.4704</p>
//         <p>Originating Dealer [or other approved licensed dealer] to obtain Odometer Statement &amp; perform vehicle inspection [including but not limited to all the following items]:</p>
        
//         <div class="bold">Vehicle Condition report</div>
// <ul>
//             <li>Exterior: Dings / dents / scrapes / &amp; or scratches</li>
//             <li>Interior: Stains / burns / tears / &amp; or rips</li>
//             <li>Cracks &amp; or pitting in windshield / sunroof / other windows / rear window</li>
//             <li>Tire wear</li>
//             <li>Missing &amp; or non-working equipment &amp; any other vehicle damage</li>
//         </ul>
//     </div>

//     <div class="section" style="
//     margin-left: 8%;
// ">
//         <span class="bold">Settlement: </span>Based on the Vehicle Condition Report – Borrower[s] &amp; or Guarantor remit settlement in full on any charges due at Vehicle Turn in to the Lender named herein.
//     </div>

// <br/><br/><br/><br/><br/>
    
// <div class="signature-line">Borrower 1 Signature: ________________________________</div>
//     <div class="signature-line">Borrower 1 Printed Name &amp; Date Signed: ________________________________</div>
//     <div class="signature-line">Borrower 2 or Guarantor Signature: ________________________________</div>
//     <div class="signature-line">Borrower 2 or Guarantor Printed Name &amp; Date Signed: ________________________________</div>
// </div>
//      <!-- Adding Vehicle Balloon Loan Terms -->
    

//     <!-- Signature Lines -->
    
    
// </body></html>
//     `;
//   };



export default function data() {

const [rows, setRows] = useState([]); 
const [selectedApplicants, setSelectedApplicants] = useState([]);
const [data, setData] = useState({
    firstApplicantName: "John Doe",
    firstApplicantAddress: "123 Main St",
    secondApplicantName: "Jane Smith",
    secondApplicantAddress: "456 Elm St",
    sellerCreditorName: "ABC Company",
    sellerCreditorAddress: "789 Oak St",
    finalScheduledPayment: "$1000",
    finalPaymentDate: "2024-05-31",
    contractDate: "2024-04-01",
    residualAmount: "$500",
    year: "2022",
    make: "Toyota",
    model: "Camry",
    style: "Sedan",
    vin: "ABC123DEF456GHI789",
    quoteNumber: 20
  });



useEffect(() => {

    


     

      document.addEventListener('DOMContentLoaded', function() {

 const storedSelectedApplicants = JSON.parse(localStorage.getItem('selectedApplicants'));
    if (storedSelectedApplicants) {
      setSelectedApplicants(storedSelectedApplicants);
    }

    printDocument();
});



// if(localStorage.getItem("selectedOption")) {
   
// var  quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails2?user_member_id=' + localStorage.getItem("selectedOption");

// }

// else if (localStorage.getItem("UserID") == 1) 
// { 
//   // Use strict comparison to compare with string '1'
//   var  quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails'; // No need for curly braces here
// } 
// else {
   
// var  quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails2?user_member_id=' + localStorage.getItem("LoginEmail");

// }

var quoteurl='';
  if (window.location.pathname==='/completedquote') {
        console.log("complete part");
      
        if (localStorage.getItem("selectedOption")) {
          // alert(1);
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails2?start_date=&end_date=&user_id=&user_member_id=' + localStorage.getItem("selectedOption");
        } else if (localStorage.getItem("selectedOptionCompany") && !localStorage.getItem("startDateRange")) {
          // alert(2);
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails2?start_date=&end_date=&user_member_id=&user_id=' + localStorage.getItem("selectedOptionCompany");
        } 
              else if (!localStorage.getItem("selectedOptionCompany") && localStorage.getItem("startDateRange") && localStorage.getItem("endDateRange")) {
            
// alert(3);
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails2?user_member_id=&user_id&start_date='+localStorage.getItem("startDateRange")+'&end_date='+localStorage.getItem("endDateRange");
        }
      
             
        else if (localStorage.getItem("selectedOptionCompany")  &&  localStorage.getItem("startDateRange") && localStorage.getItem("endDateRange")) {
       
// alert(4);
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails2?start_date='+localStorage.getItem("startDateRange")+'&end_date='+localStorage.getItem("endDateRange")+'&user_member_id=&user_id=' + localStorage.getItem("selectedOptionCompany");
        }

         else if (localStorage.getItem("UserID")) {
       // alert(5);
           // quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails';
                    quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails?user_member_id='+ localStorage.getItem("LoginEmail");

        }
    


    } 

// if(localStorage.getItem("selectedOption")) 
// {
 
// var  quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails2?user_id=&user_member_id='+localStorage.getItem("selectedOption");

// }

// else if(localStorage.getItem("selectedOptionCompany")) {

// var  quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails2?user_member_id=&user_id='+localStorage.getItem("selectedOptionCompany");

// }

// else if(localStorage.getItem("selectedOptionCompany")  && localStorage.getItem("selectedOption")) {
   
// var  quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails2?user_member_id='+ localStorage.getItem("selectedOption")+'&user_id=' + localStorage.getItem("selectedOptionCompany");

// }
//  else if (localStorage.getItem("UserID") == 1) 
// { 
//   // Use strict comparison to compare with string '1'
//   var  quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails'; // No need for curly braces here
// } 



if(quoteurl!='')
{
axios.get(quoteurl)



      .then(response => {
  

console.log("API Response:", response.data);

// localStorage.setItem("VehicaleQuoteLength", response.data.length);

let oldestDate = null;
let newestDate = null;

 oldestDate = new Date(Math.min.apply(null, response.data.map(item => new Date(item.QuoteDate))));
newestDate = new Date(Math.max.apply(null, response.data.map(item => new Date(item.QuoteDate))));



console.log("oldestDate"+oldestDate);
console.log("NewDate"+newestDate);


localStorage.setItem("olderdate",oldestDate);
localStorage.setItem("newerdate",newestDate);


const isDealer = localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer";

  
        // Transform API data to fit the table's row structure
        const newRows = response.data.map(item => (


        {





        name: item.FirstNames && item.FirstNames.includes(',') ? item.FirstNames.split(',')[0] : item.FirstNames,
  quotedate : (
            <MDTypography style={{cursor:"default"}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
              {formatDate(item.QuoteDate)}
            </MDTypography>
          ),
 



           companyname: (!isDealer  ? (
    <MDTypography style={{cursor: "default"}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
      {item.CompanyName}
    </MDTypography>
  ) : <MDTypography style={{cursor: "default",display:"none"}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
          </MDTypography>),

         
         neworusedstatus: item.Year==2024?'New':'Used',

         loanvalue: localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer" ? null : item.TotalFinancedAmount!=''?item.TotalFinancedAmount:'',

        // endvalue: item.ResidualValue!=''?item.ResidualValue:'',
         
          
        //  email: item.EmailAddresses && item.EmailAddresses.includes(',') ? item.EmailAddresses.split(',')[0] : item.EmailAddresses,
         
          vehicledetails: (
            <MDBox ml={-1} style={{display:"none"}}>
               {item.vehicleYear} {item.CarName} {item.Model}
            </MDBox>
          ),
          totalfundedprice: (
            <MDTypography style={{cursor:"default"}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
              ${commaformatted(item.TotalFinancedAmount)}
            </MDTypography>
          ),
          terms: (
            <MDTypography style={{cursor:"default"}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
              {item.Terms} months
            </MDTypography>
          ),

          expdate : (
            <MDTypography style={{cursor:"default"}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
              {formatDate2(item.QuoteDate,item.Terms)}
            </MDTypography>
          ),


   dlrcu : localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer" ? null :item.CustomerType,

 quoteid: localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer" ? null :item.ApplicationID,

 usermemberid: localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer" ? null :item.UserMemberID,
         
branch:(!isDealer  ? (
    <MDTypography style={{cursor: "default"}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
      {item.UBranch}
    </MDTypography>
  ) : null),






preappr:localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer" ? null :item.PreApprovedStatus,



 sent100data: (
 localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer" ? null :
 localStorage.getItem && localStorage.getItem("UserID") === "1" ? (
            <MDTypography style={{cursor:"default",}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
               <Select style={{width:'100px'}} value="Please select option"
                   plz select option 
          inputProps={{
            'aria-label': 'Select Yes or No',
          }}
        >
          <MenuItem value="" disabled>Select</MenuItem>
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
            </MDTypography>) : null
          ),
          sent25data: (
          localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer" ? null :
          localStorage.getItem && localStorage.getItem("UserID") === "1" ? (
            <MDTypography style={{cursor:"default",}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
               <Select style={{width:'100px'}} value="Please select option"
                    plz select option
          inputProps={{
            'aria-label': 'Select Yes or No',
          }}
        >
          <MenuItem value="" disabled>Select</MenuItem>
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
            </MDTypography>) : null
          ),


          monthlypaymentrate: (
            <MDTypography style={{cursor:"default",display:"none"}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
              {item.PifRate}
            </MDTypography>
          ),
          monthlypayment: (
            <MDTypography style={{cursor:"default",display:"none"}} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
              ${commaformatted(item.PerMonthPifAmount)}
            </MDTypography>
          ),
          pdf: (
            <MDTypography component="a" href={item.pdfUrl} variant="caption" color="text" fontWeight="medium">
              <MDBadge style={{cursor:"pointer"}} 


              onClick={() =>handleClick(...prepareHandleClickArguments(item))} badgeContent="download" color="success" variant="gradient" size="sm" />
            </MDTypography>
          ),
           signpdf: (
              
<MyDropZone2 quoteid={item.ApplicationID} />
 

                 
          ),

downloadsignedpdf: (

            <MDTypography style={{ display: item.SignedAddendumsName !== '' ? 'block' : 'none' }}  component="a" href={item.pdfUrl} variant="caption" color="text" fontWeight="medium">
              <MDBadge style={{cursor:"pointer"}} 
              onClick={() =>handleSignedClick(item.SignedAddendumsName)} badgeContent="download" color="success" variant="gradient" size="sm" />
            



      



            </MDTypography>
          ),

        
billedstatus: item.BilledStatus==0?'False':'True',



          action: (
            <MDTypography style={{cursor:"default",

    display: localStorage.getItem("CustomerType") === 'Dealer' || localStorage.getItem("CustomerType") === 'Credit Union' ? 'none' : 'block',
 
        }} component="a" href="javascript:void(0)" variant="caption" color="text" fontWeight="medium">
           <input type="checkbox"  onChange={(e) => handleCheckboxChange(e, item.ApplicationID)}
              checked={selectedApplicants.includes(item.ApplicationID)}   name="exportRows"/>
             
            </MDTypography>
 
          ),




        }));
console.log(newRows);
        setRows(newRows);
      })
}
}, [selectedApplicants]);


const handleCheckboxChange = (e, applicationID) => {
  const { checked } = e.target;
  setSelectedApplicants(prevSelectedApplicants => {
    let updatedSelectedApplicants;
    if (checked) {
      updatedSelectedApplicants = [...prevSelectedApplicants, applicationID];
    } else {
      updatedSelectedApplicants = prevSelectedApplicants.filter(id => id !== applicationID);
    }
    localStorage.setItem('selectedApplicants', JSON.stringify(updatedSelectedApplicants));
    return updatedSelectedApplicants;
  });

  console.log(selectedApplicants);
};

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
  <>
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography  display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description+"ajay"}</MDTypography>
    </MDBox>
    
</>
  );



  return {
    columns: [
     


{
  Header: localStorage.getItem("CustomerType") === 'Dealer' ? "customer" : "member",
  accessor: "name",
  width: "45%",
  align: "left"
},


        { Header: "date", accessor: "quotedate", align: "center" },
    




       { Header: localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer"?'':"dealer", accessor: "companyname", align: "left",

 style: {
      display: localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer"?'none':"block",
    }
    
},



       { Header: "n/u", accessor: "neworusedstatus", width: "45%", align: "left" },

        { Header: localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer"?'':"loan$", accessor: "loanvalue", width: "45%", align: "left" },
         //{ Header: "end$", accessor: "endvalue", width: "45%", align: "left" },
      //{ Header: "email", accessor: "email", align: "left" },
     // { Header: "vehicle details", accessor: "vehicledetails", align: "center" },
      //{ Header: "total funded price", accessor: "totalfundedprice", align: "center" },
      { Header: "term", accessor: "terms", align: "center" },

      { Header: "expdate", accessor: "expdate", align: "center" },

      { Header: localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer"?'':"dlr/cu", accessor: "dlrcu", align: "center" },
     
      { Header: localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer"?'':"ref#", accessor: "quoteid", width: "45%", align: "left" },
      
{ Header: localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer"?'':"teller#", accessor: "usermemberid", width: "45%", align: "left" },
{ Header:  localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer"?'':"branch", accessor: "branch", width: "45%", align: "left" },
{ Header: localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer"?'':"pre-appr", accessor: "preappr", width: "45%", align: "left" },

{ Header: localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer"?'':"$100 Sent", accessor: "sent100data", width: "45%", align: "left" },

{ Header: localStorage.getItem && localStorage.getItem("CustomerType") === "Dealer"?'':"$25 Sent", accessor: "sent25data", width: "45%", align: "left" },
 

      //{ Header: "monthly payment rate", accessor: "monthlypaymentrate", align: "center" },
     // { Header: "monthly payment", accessor: "monthlypayment", align: "center" },
       { Header: "addendum", accessor: "pdf", align: "center" },
{ Header: "sign addendum", accessor: "signpdf", align: "center" },
{ Header: "downloadsigned addendum", accessor: "downloadsignedpdf", align: "center" },
    



      { Header:  localStorage.getItem("CustomerType") === 'None'?"billedstatus":"", accessor: "billedstatus", align: "center" },


      { Header: localStorage.getItem("CustomerType") && localStorage.getItem("CustomerType") === 'Dealer' || localStorage.getItem("CustomerType") === 'Credit Union'?"":"action", accessor: "action", align: "center" },
    


  
    ],

    rows: rows,
  };
}
