import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';

const MyBarChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
                label:localStorage.getItem('CustomerType') && localStorage.getItem('CustomerType')==="Dealer"?[]:"Pending Loans",

        data: [],
        backgroundColor: localStorage.getItem('CustomerType') && localStorage.getItem('CustomerType')==="Dealer"?"rgb(240, 242, 245, 1)":'rgb(251, 130, 39, 0.6)',
        borderColor: localStorage.getItem('CustomerType') && localStorage.getItem('CustomerType')==="Dealer"?"rgb(240, 242, 245, 1)":'rgb(251, 130, 39, 1)',
        borderWidth: localStorage.getItem('CustomerType') && localStorage.getItem('CustomerType')==="Dealer"?"":1,
      },
      {
        label:  localStorage.getItem('CustomerType') && localStorage.getItem('CustomerType')==="Dealer"?"Closed Deals":"Closed Loans",
        data: [],
        backgroundColor: 'rgba(54, 253, 82, 0.6)',
        borderColor: 'rgba(54, 253, 82, 1)',
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {






    const fetchData = async () => {
      try {
        const countquoteurl = `${process.env.REACT_APP_BASE_URL}authenticationAPI/GetQuoteCountsByTimeGranularity?time_granularity=${localStorage.getItem("granularity")}&user_member_id=${localStorage.getItem("LoginEmail")}`;
        const response = await axios.get(countquoteurl);

        if (response.data) {
          const labels = response.data.map(item => {
            // const date = new Date(item.Period + '-01');
            // return date.toLocaleString('default', { month: 'short', year: 'numeric' }).replace(' ', '-');
          




 let formattedLabel;
        const period = item.Period;
        const granularity = localStorage.getItem("granularity");

        switch (granularity) {
          case "yearly":
            formattedLabel = new Date(period + '-01').toLocaleString('default', { year: 'numeric' });
            break;
          case "monthly":
            formattedLabel = new Date(period + '-01').toLocaleString('default', { month: 'short', year: 'numeric' }).replace(' ', '-');
            break;
          case "daily":
            formattedLabel = new Date(period).toLocaleString('default', { month: 'short', day: 'numeric', year: 'numeric' }).replace(',', '');
            break;
          case "quarterly":
            // Assuming period is in YYYY-Q format, e.g., "2023-Q1"
            const [year, quarter] = period.split('-Q');
            const quarterStartMonth = (parseInt(quarter) - 1) * 3 + 1;
            const quarterEndMonth = quarterStartMonth + 2;
            formattedLabel = `${year}  ${new Date(year, quarterStartMonth - 1).toLocaleString('default', { month: 'short' })} - ${new Date(year, quarterEndMonth - 1).toLocaleString('default', { month: 'short' })}`;
            break;
          default:
            formattedLabel = period; // Default to period as-is if granularity is not recognized
        }

        return formattedLabel;










          });

          const pendingCounts = response.data.map(item => item.PendingCount);
          const completeCounts = response.data.map(item => item.CompleteCount);

          setChartData({
            labels: labels,
            datasets: [
              {
                label:localStorage.getItem('CustomerType') && localStorage.getItem('CustomerType')==="Dealer"?[]:"Pending Loans",
                data: localStorage.getItem('CustomerType') && localStorage.getItem('CustomerType')==="Dealer"?[]:pendingCounts,
                backgroundColor: localStorage.getItem('CustomerType') && localStorage.getItem('CustomerType')==="Dealer"?"rgb(240, 242, 245, 1)":'rgb(251, 130, 39, 0.6)',
                borderColor: localStorage.getItem('CustomerType') && localStorage.getItem('CustomerType')==="Dealer"?"rgb(240, 242, 245, 1)":'rgb(251, 130, 39, 1)',
                borderWidth: localStorage.getItem('CustomerType') && localStorage.getItem('CustomerType')==="Dealer"?"":1,
              },
              {
        label:  localStorage.getItem('CustomerType') && localStorage.getItem('CustomerType')==="Dealer"?"Closed Deals":"Closed Loans",
                data: completeCounts,
                backgroundColor: 'rgba(54, 253, 82, 0.6)',
                borderColor: 'rgba(54, 253, 82, 1)',
                borderWidth: 1,
              },
            ],
          });






 // setChartData(prevChartData => ({
 //            ...prevChartData,
 //            labels: labels,
 //            datasets: [
 //              {
 //                ...prevChartData.datasets[0], // Update only the first dataset
 //                label: localStorage.getItem('CustomerType') === 'Dealer' ? [] : 'Pending Loans',
 //                data: localStorage.getItem('CustomerType') === 'Dealer' ? [] : pendingCounts,
 //                backgroundColor: localStorage.getItem('CustomerType') === 'Dealer' ? 'rgba(54, 253, 82, 0.6)' : 'rgb(251, 130, 39, 0.6)',
 //                borderColor: localStorage.getItem('CustomerType') === 'Dealer' ? 'rgba(54, 253, 82, 1)' : 'rgb(251, 130, 39, 1)',
 //              },
 //              prevChartData.datasets[1], // Keep the second dataset unchanged
 //            ],
 //          }));





        }
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run effect only once on mount

  return (
    <div>
      <Bar data={chartData} id="myBarChartCanvas" />
    </div>
  );
};

export default MyBarChart;
