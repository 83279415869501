import { PDFDocument, rgb } from 'pdf-lib';

export const signPDFLive = async (file, userName) => {
  const pdfDoc = await PDFDocument.load(await file.arrayBuffer());

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  const { width, height } = firstPage.getSize();
  const textSize = 20;

  firstPage.drawText(`Signed by ${userName}`, {
    x: 50,
    y: height - 4 * textSize,
    size: textSize,
    color: rgb(0, 0, 1),
  });

  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], { type: 'application/pdf' });
}

export const signPDFEmail = async (file, userName) => {
  const pdfDoc = await PDFDocument.load(await file.arrayBuffer());

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  const { width, height } = firstPage.getSize();
  const textSize = 20;

  firstPage.drawText(`Signed by ${userName}`, {
    x: 50,
    y: height - 4 * textSize,
    size: textSize,
    color: rgb(0, 0, 1),
  });

  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], { type: 'application/pdf' });
};
