import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DialogResetComponent from './DialogResetComponent';

export default function Data() {
  const [rows, setRows] = useState([]);
 
  const [open, setOpen] = useState(false);

const [selectedMemberId, setSelectedMemberId] = useState(null); // State to hold selected member ID

  const sendEmail = (message) => {
    const MANDRILL_URL12 = process.env.REACT_APP_MANDRILL_API_URL;
    var MANDRILL_URL = MANDRILL_URL12.replace(/"/g, '');

    // Send email
    axios.post(MANDRILL_URL, message)
      .then(response => {
        console.log('Email sent successfully:', response.data);
        if(response.data[0].status) {
          // window.location.reload();
        }
      })
      .catch(error => {
        console.error('Error sending email:', error);
      });
  };


  const handleButtonClick2 = (member_id, status) => {
    // Handle button click to approve/disapprove
    const customerurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/updatecustomerstatus';
    const data = {
      user_member_id: member_id,
      status_value: parseInt(status),
    };

    axios.post(customerurl, data)
      .then(response => {
        console.log("API Response:", response.data);
        // Show success message or handle response as needed
      




  const getemailurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetEmailByMemberID';
    const data = {
      user_member_id: member_id
    };

    axios.post(getemailurl, data)
      .then(response => {
 
        console.log("API Response12:", response.data[0].Email);

        // Show success message or handle response as needed
       const API_KEY12 = process.env.REACT_APP_EMAIL_API_KEY;
       var API_KEY= API_KEY12.replace(/"/g, '');

 const MESSAGE = {
                "key": API_KEY,
                "message": {
                  "text": "Member has been Approved by superadmin Now can acces your account",
                  "subject": "Member Approval Notification",
                  "from_email": "pifapp@myautoline.com",
                  "to": [{
                    "email": response.data[0].Email, // Use the customer's email here
                    "name": "PIF",
                    "type": "to"
                  }]
                }
              };




              sendEmail(MESSAGE);




  });




      })
      .catch(error => {
        console.error("API Error:", error);
        // Handle error scenarios
      });
  };

 const handleButtonReset = (member_id) => {

console.log("aa ja");

    setOpen(true); // Open the dialog when "Reset Password" is clicked
setSelectedMemberId(member_id);

  };
  useEffect(() => {

    console.log("Data component - open:", open);  
    console.log("Data component - open:", selectedMemberId);  
    // Fetch data on component mount
    const customer_url = localStorage.getItem("UserID") === '1'
      ? `${process.env.REACT_APP_BASE_URL}authenticationAPI/GetCustomerDetails`
      : `${process.env.REACT_APP_BASE_URL}authenticationAPI/GetCustomerMemberDetailsByUserID?user_id=${localStorage.getItem("UserID")}`;

    axios.get(customer_url)
      .then(response => {
        console.log("API Response:", response.data);



        const newRows = response.data.map(item => ({
          branchname: item.Branch,
          name: item.FirstName,
          title: item.Title,
          email: item.Email,
          id: item.UserMemberID,
          status: item.Status === 0 ? "Not Approved" : "Approved",
          action: (
            <>
              <button className="btn" onClick={() => handleButtonClick2(item.UserMemberID, item.Status === 0 ? 1 : 0)}>
                {item.Status === 0 ? "Approve" : "Disapprove"}
              </button>
             
            </>
          ),

           reset: (
            <>
               <button className="btn" onClick={() => handleButtonReset(item.UserMemberID)}>Reset Password</button>

              <DialogResetComponent
              key={item.UserMemberID}
                open={open && selectedMemberId === item.UserMemberID}
                selectedMemberId={selectedMemberId}
               
              />
            </>
          )


        }));
        setRows(newRows);
      })
      .catch(error => {
        console.error("API Error:", error);
        // Handle error scenarios
      });
  }, [open,selectedMemberId]);

  return {
    columns: [
      { Header:localStorage.getItem("CustomerType")==="Dealer"?"Dealer location":"Branch Name"
                   , accessor: "branchname", width: "45%", align: "left" },
      { Header: "Staff", accessor: "name", width: "45%", align: "left" },
      { Header: "Title", accessor: "title", width: "45%", align: "left" },
      { Header: "E-mail", accessor: "email", align: "left" },
      { Header: "Teller#", accessor: "id", align: "left" },
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "Action", accessor: "action", align: "left" },

{ Header: "Reset Password", accessor: "reset", align: "left" },


    ],
    rows: rows,
  };
}
