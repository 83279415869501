import React from "react";
import { useEffect } from "react";

import { useHistory } from "react-router-dom"; // Import useHistory hook for navigation
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import axios from "axios";


function Logout() {
 // const history = useHistory(); // Initialize useHistory hook

  // Function to handle logout
useEffect(() => {


var tokenData = {token:'',user_member_id:localStorage.getItem("LoginEmail")};

axios 
      .post(process.env.REACT_APP_BASE_URL + `authenticationAPI/UpdateToken`, tokenData)
      .then((res) => {

 if (res.data.status) {

//alert(res.data.status);
 }
});

  // const handleLogout = () => {
    // Clear the authentication token or user session
    // For example, remove the token from local storage
    localStorage.removeItem("UserID");
    localStorage.removeItem("LoginName");
    localStorage.removeItem("LoginEmail");
localStorage.removeItem("VehicleQuoteLengthData");
localStorage.removeItem("selectedOption");
localStorage.removeItem("selectedApplicants");
localStorage.removeItem("UserID");
localStorage.removeItem("Logo");
localStorage.removeItem("CustomerType"); 
localStorage.removeItem("newerdate");
localStorage.removeItem("olderdate");
localStorage.removeItem("token");
localStorage.removeItem("TempPasswordStatus");
localStorage.removeItem("Role");

   
    // Redirect the user to the login page
   // history.push("/authentication/sign-in");

    window.location.href='/authentication/sign-in';
  //};

},[]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
     
      <Footer />
    </DashboardLayout>
  );
}

export default Logout;
