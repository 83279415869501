/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import * as XLSX from "xlsx";
const ExcelJS = require('exceljs');
import { saveAs } from 'file-saver';
const fs = require('fs');
import { useState, useEffect } from "react";   

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import axios from "axios";
import Swal from "sweetalert";
import { useNavigate } from 'react-router-dom';

// import DatePicker from '@mui/lab/DatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns'; // You need to import a date adapter
// import TextField from '@mui/material/TextField';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";







function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
 const [data, setData] = useState([]);
 const [quote, setQuote] = useState([]);
    const [options, setOptions] = useState([]);
const [optionsCompany, setOptionsCompany] = useState([]);

   const [searchQuery, setSearchQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [selectedOptionCompany, setSelectedOptionCompany] = useState("");

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
const navigate = useNavigate();




 // const handleInputChange = (event) => {
 //    setSearchQuery(event.target.value);
 //  };

 

 //  // Filter options based on the search query
 //  const filteredOptions = options.filter((option) =>
 //    option.label.toLowerCase().includes(searchQuery.toLowerCase())
 //  );


 // const fetchData = async () => {
 //    try {
 //      // Fetch data from the API

 //      const response = await fetch(process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetUserMember');
 //      const data = await response.json();
 //      // Update the options state variable with the fetched data
 //      setOptions(data);
 //    } catch (error) {
 //      console.error("Error fetching data:", error);
 //    }
 //  };

const handleStartDateChange = (event) => {
    const date = event.target.value;

    localStorage.setItem("startDateRange",date);
    setStartDate(date);
    window.location.reload();
  };

  const handleEndDateChange = (event) => {
    const date = event.target.value;
    
localStorage.setItem("endDateRange",date);
    setEndDate(date);
   window.location.reload();
  };

 const fetchCompnayData = async () => {
    try {
      // Fetch data from the API

      const response = await fetch(process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompnayName');
      const data = await response.json();
      // Update the options state variable with the fetched data
      setOptionsCompany(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {

console.log("default popup");

if(localStorage.getItem("TempPasswordStatus") && localStorage.getItem("TempPasswordStatus")==1)
{
    // Swal({
    //                  text: "Please Change Your Temperory Password! Can change Your Temperory Password on Click Reset Button",
    //                 icon: "info",
    //    });
    //window.location.href='/customer';


Swal({
        text: "Please Change Your Temporary Password! You can change your Temporary Password by clicking the Reset Button",
        icon: "info",
        buttons: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        },
        dangerMode: true
      }).then(() => {
        if (window.location.pathname !== '/customer') {
          navigate('/customer');
        }
      });

}



var quoteurl='';
// Check if window.location is defined
// if (window.location && window.location.href) {
    if (window.location.pathname==='/completedquote') {
        console.log("complete part");
      
       
if (localStorage.getItem("selectedOption")) {
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails2?start_date=&end_date=&user_id=&user_member_id=' + localStorage.getItem("selectedOption");
        } else if (localStorage.getItem("selectedOptionCompany") && !localStorage.getItem("startDateRange")) {
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails2?start_date=&end_date=&user_member_id=&user_id=' + localStorage.getItem("selectedOptionCompany");
        } 
              else if (!localStorage.getItem("selectedOptionCompany") && localStorage.getItem("startDateRange") && localStorage.getItem("endDateRange")) {
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails2?user_member_id=&user_id&start_date='+localStorage.getItem("startDateRange")+'&end_date='+localStorage.getItem("endDateRange");
        }
      
             
        else if (localStorage.getItem("selectedOptionCompany") && localStorage.getItem("selectedOption") &&  localStorage.getItem("startDateRange") && localStorage.getItem("endDateRange")) {
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails2?start_date='+localStorage.getItem("startDateRange")+'&end_date='+localStorage.getItem("endDateRange")+'&user_member_id=' + localStorage.getItem("selectedOption") + '&user_id=' + localStorage.getItem("selectedOptionCompany");
        }

         else if (localStorage.getItem("UserID")) {
           // quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails';
                    quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails?user_member_id='+ localStorage.getItem("LoginEmail");

        }

    } else if (window.location.pathname==='/quote') 
    {
        console.log("not complete");
        
        
        if (localStorage.getItem("selectedOption")) {
            //alert(1);
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetQuoteDetails2?start_date=&end_date=&user_id=&user_member_id=' + localStorage.getItem("selectedOption");
        } else if (localStorage.getItem("selectedOptionCompany") && !localStorage.getItem("startDateRange")) {
           //alert(2);
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetQuoteDetails2?start_date=&end_date=&user_member_id=&user_id=' + localStorage.getItem("selectedOptionCompany");
        } 
        else if ( !localStorage.getItem("selectedOptionCompany") &&localStorage.getItem("startDateRange") && localStorage.getItem("endDateRange")) {
            //alert(3);
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetQuoteDetails2?user_member_id=&user_id&start_date='+localStorage.getItem("startDateRange")+'&end_date='+localStorage.getItem("endDateRange");
        }
        else if (localStorage.getItem("selectedOptionCompany")  &&  localStorage.getItem("startDateRange") && localStorage.getItem("endDateRange")) {
           //alert(4);
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetQuoteDetails2?start_date='+localStorage.getItem("startDateRange")+'&end_date='+localStorage.getItem("endDateRange")+'&user_member_id=&user_id=' + localStorage.getItem("selectedOptionCompany");
        } else if (localStorage.getItem("UserID")) {
           // alert(5);
            quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetQuoteDetails?user_member_id='+ localStorage.getItem("LoginEmail");
        }
    }
  

//}

console.log("quoteurl");
console.log(quoteurl);

// else {
//    alert("hello4");
// var  quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetQuoteDetails2?user_id='+ localStorage.getItem("selectedOptionCompany")+'&user_member_id=' + localStorage.getItem("LoginEmail");

// }
if(quoteurl!==''){
axios.get(quoteurl)



      .then(response => {
  

console.log("API Response2:", response.data);

setQuote(response.data);
});
}

// fetchData();
fetchCompnayData();


    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

 // Filter options based on the search query
  

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [navigate,dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

// const filteredOptions = options.filter((option) =>
//     option.label.toLowerCase().includes(searchQuery.toLowerCase())
//   );

 const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

    localStorage.setItem("selectedOption",event.target.value);

    window.location.reload();
  };


 const handleOptionChangeCompany = (event) => {

if(event.target.value=='all')
{
    setSelectedOptionCompany('');

   localStorage.setItem("selectedOptionCompany",'');

    window.location.reload();
 

}
else{
    setSelectedOptionCompany(event.target.value);

   localStorage.setItem("selectedOptionCompany",event.target.value);

    window.location.reload();
 

}
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

const commaformatted =(data) =>
{
      if (data === null || data === undefined) return ''; // Handle null or undefined
    const dataStr = String(data);

    const numbersAndDecimal = dataStr.replace(/[^0-9.]/g, ""); // Keep only digits and dot
    const sections = numbersAndDecimal.split("."); // Split at the dot, if present
    const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousand separators
    const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places

    const formattedValue =
      sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
      return  '$'+formattedValue;
}






  const handleExport = async () => 
  {
    console.log("quoteraja");
    console.log(quote);
    //console.log(localStorage.getItem("selectedApplicants"));

const selectedApplicants = JSON.parse(localStorage.getItem("selectedApplicants")) || [];
const filteredArray = quote.filter(applicant => selectedApplicants.includes(applicant.ApplicationID));

// quote.filter(applicant => applicant.ApplicationID === 51)





if(selectedApplicants!='')
{
var  quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/updatebilledstatus';

 const data = {
      quote_id: selectedApplicants.join(','),
      status_value: parseInt(1),
    };

      const response =  axios.post(quoteurl, data);
     
        if(response.status)
        {
        

 Swal({
      text: `Billed Status Has been Updated:`,
      icon: "success",
    });


}

}



console.log("quoteraja1");
   // console.log(quote);
    console.log(filteredArray);

const keyMapping = {
    "CarName": "Make",
    "PifRate":"Loan%",
    "ConventionalRate":"Cu Loan%",
    "ProfitAmount":"Invoice Amt",
    "TotalFinancedAmount":"Loan Amt",
    "FirstNames":"Member",
    "Terms":"Term"
    // Add other key mappings as needed
};


   
const columnsToRemove = ["usermember","usermemberemail", "ApplicationID","EmailAddress","PerMonthPifAmount",];
const desiredColumnsOrder = ["Member", "Make", "Model", "Loan Amt","Term", "Loan%", "Cu Loan%", "Invoice Amt"];
const quoteData1 = filteredArray.map(item => {
    const newItem = {};
    Object.keys(item).forEach(oldKey => {
        // If there's a mapping for the old key, use the new key, otherwise use the old key
        const newKey = keyMapping[oldKey] || oldKey;
        if (!columnsToRemove.includes(oldKey)) {
        newItem[newKey] = item[oldKey];
       }

    });
    return newItem;
});

console.log("quoteData1");
console.log(quoteData1);

// Convert QuoteDate strings to Date objects
const dates = quoteData1.map(item => new Date(item.QuoteDate));
// Get the oldest and newest dates
const oldestDate = new Date(Math.min(...dates));
const newestDate = new Date(Math.max(...dates));
console.log("Oldest Date:", oldestDate);
console.log("Newest Date:", newestDate);


localStorage.setItem("olderdate",oldestDate);
localStorage.setItem("newerdate",newestDate);


const quoteData2 = quoteData1.map(item => {
    const newItem1 = {};
    desiredColumnsOrder.forEach(key => {
        // Only copy the key if it's not in the columnsToRemove array
        
            newItem1[key] = item[key];
        
    });
    return newItem1;
});

console.log(quoteData2);

    console.log("fello");

const quoteData3 = quoteData2.map(item => {
//  const firstName = item.Member.split(',')[0].trim(); // Get the first name

const firstName=item.Member && item.Member.includes(',') ? item.Member.split(',')[0] : item.Member



  return { ...item, Member: firstName };
});




   

const quoteData = quoteData3.map(item => {
  const loanAmount = commaformatted(item["Loan Amt"]); // Get the first name
  const invoiceAmount = commaformatted(item["Invoice Amt"]);
  return { ...item, "Loan Amt": loanAmount, "Invoice Amt": invoiceAmount };
});


console.log(quoteData);

    console.log("fello1");

let invoiceAmounts = [];

// Iterate over each object in the array
for (let i = 0; i < quoteData.length; i++) {
    let item = quoteData[i];
    for (let key in item) {
        if (key === "Invoice Amt") {
            console.log(item[key]); // Log the invoice amount
            invoiceAmounts.push(item[key]); // Add the invoice amount to the array
        }
    }
}

console.log("invoiceAmounts");
console.log(invoiceAmounts);

let totalSum = 0;
for (let i = 0; i < invoiceAmounts.length; i++) 
{
    let amountStr = invoiceAmounts[i];
    let amountNum = parseFloat(amountStr.replace("$", "").replace(/,/g, ''));
    totalSum += amountNum;
}

var totalquoteamount="$" + totalSum.toFixed(2); 




// console.log(quote[0]['CompanyName']);
// console.log("ajay");
    var companyname ='Credit Union:'+' '+"Century Federal";

const date = new Date(localStorage.getItem('olderdate'));
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const month = months[date.getMonth()];
const day = date.getDate();
const year = date.getFullYear();
const formattedolderDate = `${month} ${day} ${year}`;

const date1 = new Date(localStorage.getItem('newerdate'));
const month1 = months[date1.getMonth()];
const day1 = date1.getDate();
const year1 = date1.getFullYear();
const formattednewerDate = `${month1} ${day1} ${year1}`;


var invoicemonth ='Invoice month of:'+' '+formattedolderDate+'-'+formattednewerDate;


// Create a new workbook and an empty worksheet
const workbook = new ExcelJS.Workbook();
const worksheet = workbook.addWorksheet('DataSheet');

// Add an image
// const imageId = workbook.addImage({
//     filename: localStorage.getItem('Logo'), // Replace with the path to your logo image
//     extension: 'png',
// });

// // Position the image in the worksheet
// worksheet.addImage(imageId, {
//     tl: { col: 0, row: 0 }, // Top-left position of the image (column, row)
//     ext: { width: 100, height: 50 }, // Width and height of the image
// });

//const logoUrl = localStorage.getItem('Logo');

// console.log('logoUrl');
// console.log(logoUrl);

//addImageToWorkbook(workbook, logoUrl, worksheet);

   const response = await fetch('/logo.png');
      const blob = await response.blob();

      // Convert the blob to a Uint8Array
      const arrayBuffer = await blob.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);

      // Create a new workbook and a worksheet
   

      // Add the image to the workbook
      const imageId = workbook.addImage({
        buffer: uint8Array,
        extension: 'png',
      });
worksheet.addRow([]);
worksheet.addRow([]);


worksheet.addImage(imageId, {
        tl: { col: 0, row: 0 },
        ext: { width: 60,height:30 },
      });
worksheet.addRow([]);
worksheet.addRow([]);




worksheet.getColumn(1).width = 50;

worksheet.mergeCells('A1:A2');

// Add more static content starting at a specific row
// const companyname = 'Company Name';  // Replace with actual company name
// const invoicemonth = 'Invoice Month';  // Replace with actual invoice month
worksheet.getCell('A4').value = companyname;
worksheet.getCell('C4').value = invoicemonth;

// Merging cells for additional headers
worksheet.mergeCells('A4:B5');
worksheet.mergeCells('C4:G5');

// Add JSON data to the worksheet (assuming quoteData is an array of objects)
// const quoteData = [
//     { header1: 'value1', header2: 'value2' }, // Replace with actual data
//     { header1: 'value3', header2: 'value4' }
// ];

 const headers = [
        'Member', 'Make', 'Model', 'Loan Amt', 'Term', 'Loan%', 'Cu Loan%', 'Invoice Amt'
    ];

   const borderStyle = {
  top: { style: 'thin', color: { argb: 'FF000000' } },  // Black color borders
  left: { style: 'thin', color: { argb: 'FF000000' } },
  bottom: { style: 'thin', color: { argb: 'FF000000' } },
  right: { style: 'thin', color: { argb: 'FF000000' } }
};
    // Add headers to the worksheet
   const headerRow = worksheet.addRow(headers);

  headerRow.eachCell((cell) => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFF00' } // Yellow background
        };
        cell.font = { bold: true };
         cell.border = borderStyle; 
    });




quoteData.forEach((data, index) => 
{
   const row =  worksheet.addRow(Object.values(data));
     row.eachCell((cell) => {
    cell.border = borderStyle;  // Apply border style
  });
});

   // Adjust column widths
    setColumnWidths(worksheet);



worksheet.addRow([]);
worksheet.addRow([]);


worksheet.getCell('A10').value = 'TOTAL';
worksheet.getCell('H10').value = totalquoteamount;

// Merging cells for additional headers
worksheet.mergeCells('A10:G10');




 const bufferExcel = await workbook.xlsx.writeBuffer();
      const blobExcel = new Blob([bufferExcel], { type: 'application/octet-stream' });
      saveAs(blobExcel, 'Century_Federal_Invoices.xlsx');




};




// Function to set column widths based on their content
function setColumnWidths(worksheet) {
    worksheet.columns.forEach(column => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, cell => {
            let cellLength = cell.value ? cell.value.toString().length : 0;
            if (cellLength > maxLength) {
                maxLength = cellLength;
            }
        });
   console.log("aj"+maxLength);
        column.width = maxLength < 10 ? 10/2+2 : maxLength/2 + 4; // Ensuring a minimum width and adding extra space
   // console.log("aj"+column.width);


    });
}


function   saveWorkbook(workbook, filename) {
    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        saveAs(blob, filename);
    }).catch((err) => {
        console.error('Error writing file:', err);
    });
}

 function s2ab(s) {
        const buffer = new ArrayBuffer(s.length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buffer;
    }
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)} style={window.location.href.includes('dashboard') ? { display: "none" } : {}}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            




{  window.location.href.includes('quote') &&  (localStorage.getItem("UserID")==1)?(localStorage.getItem("selectedOption")!='')?
        
<>
      

         <FormControl  sx={{ minWidth: 40, pr: 1, pb: 1 }}>


 


   

<div style={{fontSize:"16px",fontFamily:"Roboto",marginRight: "8px",
    marginBottom: "15px"}}>
<label style={{fontWeight:600}}>Start Date: </label>
      <input type="date" style={{display: 'block',
    width: '100%',
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border:'1px solid #ced4da',borderRadius: '0.25rem'

}}
value={localStorage.getItem('startDateRange')} onChange={handleStartDateChange} />
 </div>     

<div style={{fontSize:"16px",fontFamily:"Roboto"}}>
      <label style={{fontWeight:600,marginRight:"8px"}}>End Date: </label>
      <input type="date"  style={{display: 'block',
    width: '100%',
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border:'1px solid #ced4da',borderRadius: '0.25rem'

}} value={localStorage.getItem('endDateRange')} onChange={handleEndDateChange} />
</div>

 <MDBox pr={1}>

            </MDBox>


</FormControl></>:"":""

}


{ window.location.href.includes('quote') &&  (localStorage.getItem("UserID")==1)?<>
         <FormControl   pr={1} pb={1} sx={{ minWidth: 80, pr: 1, pb: 1 }}>
         

 <Select
 style={{"padding":"12px",marginTop:"8px"}}
        labelId="select-label"
        id="select"
         value={selectedOptionCompany}
        onChange={handleOptionChangeCompany}

  displayEmpty
  renderValue={(value) => {
    // Check if 'value' is empty, and if so, display the placeholder text
    if (value === "") {
      return "Please select company";
    }


 const selectedOptionCompany = optionsCompany.find(option => option.UserID === value);
    // Return the FirstName of the found option, or "Please select a member" if none found
    return selectedOptionCompany ? selectedOptionCompany.CompanyName : "Please select a company";



   
  }}



        >
          Please Select A Member
 {/* Default menu item */}
  <MenuItem value="" disabled>
    Please select a Company
  </MenuItem>

<MenuItem value="all" >
    All Company
  </MenuItem>

 {optionsCompany.map((option) => (
  
         
          <MenuItem className={localStorage.getItem('selectedOptionCompany') == option.UserID ? 'selecteditem' : 'test'} key={option.UserID} value={option.UserID}>
            { option.CompanyName}
          </MenuItem>
        
        ))}
      </Select>

 
</FormControl>  <FormControl><Select
 style={{"padding":"12px",marginTop:"8px",display:"none"}}
        labelId="select-label"
        id="select"
         value={selectedOption}
        onChange={handleOptionChange}

  displayEmpty
  renderValue={(value) => {
    // Check if 'value' is empty, and if so, display the placeholder text
    if (value === "") {
      return "Please select a member";
    }


 const selectedOption = options.find(option => option.UserMemberID === value);
    // Return the FirstName of the found option, or "Please select a member" if none found
    return selectedOption ? selectedOption.FirstName : "Please select a member";



   
  }}



        >
          Please Select A Member
 {/* Default menu item */}
  <MenuItem value="" disabled>
    Please select a member
  </MenuItem>

 {options.map((option) => (
  
         
          <MenuItem className={localStorage.getItem('selectedOption') == option.UserMemberID ? 'selecteditem' : 'test'} key={option.UserMemberID} value={option.UserMemberID}>
            { option.FirstName}
          </MenuItem>
        
        ))}
      </Select>
</FormControl></>:""

}
            


{/*<DatePicker
        label="Select Date"
        value={localStorage.getItem('startDateRange')}
        onChange={handleStartDateChange}
        renderInput={(params) => <TextField {...params} />}
       
      />*/}

{  window.location.href.includes('quote') &&  (localStorage.getItem("UserID")==1)?(localStorage.getItem("selectedOption")!='')?
         <FormControl   pr={1} pb={1} sx={{ minWidth: 40, pr: 1, pb: 1 }}>
 <input type="button"  style= {{width:"120px",marginTop:"10px",padding:"10px",border:"1px solid #002f6c",background:"#002f6c",color:"white","borderRadius":"3px","cursor":"pointer"}} value="Export" onClick={handleExport}/>
</FormControl>:"":""

}         


            <MDBox color={light ? "white" : "inherit"}>
              <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>
              <IconButton style={{display:"none"}}
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton style={{display:"none"}}
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}    >settings</Icon>
              </IconButton>
              <IconButton  style={{display:"none"}}
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
