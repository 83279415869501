/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/completedquotes/data/authorsTableData";
import projectsTableData from "layouts/completedquotes/data/projectsTableData";

function CompletedQuotes() {
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div id="test" style={{display:"none"}}><h1>test</h1></div>

    

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                
                borderRadius="lg"
                coloredShadow="info"
              
 style={{backgroundColor:"rgb(0, 47, 108)",border:"1px solid rgb(0, 47, 108)"}}
              >
                <MDTypography variant="h6" color="white">

{
window.location.pathname === '/customer' && localStorage.getItem("CustomerType") === 'Dealer'
    ? 'Dealer Roster'
    : (
      window.location.pathname === '/customer'
        ? 'CU Roster'
        : (
          window.location.pathname === '/quote' && localStorage.getItem("CustomerType") === 'Dealer'
            ? 'Pending Deals'
            : (
              window.location.pathname === '/quote'
                ? 'Pending Loans'
                : (
                  window.location.pathname === '/completedquote' && localStorage.getItem("CustomerType") === 'Dealer'
                    ? 'Closed Deals'
                    : (
                      window.location.pathname === '/completedquote'
                        ? 'Closed Loans'
                        : window.location.pathname
                    )
                )
            )
        )
    )

}



                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={true}
                  pagination={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} style={{display:"none"}}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CompletedQuotes;
