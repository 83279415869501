/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { useState,useEffect } from "react";
import  axios  from "axios";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import MyBarChart from '../MyBarChart';
import PieChartComponent from '../PieChartComponent';
function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [vehicleQuoteLengthDataNumber, setVehicleQuoteLengthDataNumber] = useState(0);
const [vehicleCompletedQuoteLengthDataNumber, setVehicleCompletedQuoteLengthDataNumber] = useState(0);
const [CustomerLengthDataNumber, setCustomerLengthDataNumber] = useState(0);
 const [granularity, setGranularity] = useState('monthly');

const [chartData, setChartData] = useState({});
    // Function to handle granularity change
    const handleGranularityChange = (e) => {
        const newGranularity = e.target.value;
        setGranularity(newGranularity);
        localStorage.setItem('granularity', newGranularity);  // Save to local storage

        window.location.reload();
    };


  useEffect(() => {

  const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}authenticationAPI/GetQuoteCountsByTimeGranularity?time_granularity=daily&user_member_id=${localStorage.getItem("LoginEmail")}`
        );
        setChartData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();



  // Save to local storage
if (localStorage.getItem("UserID")) 
{ 
  var  quoteurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetQuoteDetails?user_member_id=' + localStorage.getItem("LoginEmail"); // No need for curly braces here
} 

axios.get(quoteurl)

      .then(response => {
        // Assuming response.data is an array
        setVehicleQuoteLengthDataNumber(response.data.length);
        localStorage.setItem("VehicleQuoteLengthData", response.data.length);
      })




if (localStorage.getItem("UserID")) 
{ 
    var  quotecompletedurl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/GetCompletedQuoteDetails?user_member_id=' + localStorage.getItem("LoginEmail"); // No need for curly braces here
}

axios.get(quotecompletedurl)

      .then(response => {
        // Assuming response.data is an array
        setVehicleCompletedQuoteLengthDataNumber(response.data.length);
        localStorage.setItem("VehicleCompletedQuoteLengthData", response.data.length);
      })



var customer_url='';
if(localStorage.getItem("UserID")==1)
{
customer_url=process.env.REACT_APP_BASE_URL + `authenticationAPI/GetCustomerDetails`
}
else
{
  customer_url=process.env.REACT_APP_BASE_URL + `authenticationAPI/GetCustomerMemberDetailsByUserID?user_id=${localStorage.getItem("UserID")}`
}


    axios.get(customer_url)  // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
     


      .then(response => {


console.log("API Response:", response.data);

 setCustomerLengthDataNumber(response.data.length);
        localStorage.setItem("CustomerLengthData", response.data.length);
});


    
  }, []);

var pifurl1=process.env.REACT_APP_EXTERNAL_URL;
var pifurl=pifurl1.replace(/"/g, '');

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                href={"customer"}
                color="info"
                icon="weekend"
                title={localStorage.getItem("CustomerType") && localStorage.getItem("CustomerType")==="Dealer"?"Dealer Roster":"Frontline Roster Activity"}
                count={localStorage.getItem("CustomerLengthData")?localStorage.getItem("CustomerLengthData"):CustomerLengthDataNumber}
                percentage={{
                  color: "dark",
                  amount: "",
                  label: "View",
                }}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                href={`${pifurl}?token=${localStorage.getItem('token')}`}
                color="warning"
                icon="weekend"
                
title={localStorage.getItem("CustomerType") && localStorage.getItem("CustomerType")==="Dealer"?"Vehicle Quote For Calculator":"Pay-It-Forward Calculators"}



                count={''}
                percentage={{
                  color: "dark",
                  amount: "",
                  label: "View",
                }}
              />
            </MDBox>
          </Grid>



 {localStorage.getItem("CustomerType") === "Dealer" && (
<Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                 href="javascript:void(0)"  
                color="warning"
                icon="weekend"
                
title={'Submit Credit App'}



                count={''}
                percentage={{
                  color: "dark",
                  amount: "",
                  label: "View",
                }}
              />
            </MDBox>
          </Grid>

)}

       
{localStorage.getItem("CustomerType") && localStorage.getItem("CustomerType")==="Credit Union" && (

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
              color="secondary"
              href="quote"   
                icon="leaderboard"
                title="Pending Loans & $100 Coupons"

title={localStorage.getItem("CustomerType") && localStorage.getItem("CustomerType")==="Dealer"?"Pending Deals":"Pending Loans & $100 Coupons"}

                count={localStorage.getItem("VehicleQuoteLengthData")?localStorage.getItem("VehicleQuoteLengthData"):vehicleQuoteLengthDataNumber}
                percentage={{
                  color: "",
                  amount: "", 
                  label: "View",
                }}
              />
            </MDBox>
          </Grid>

)}
            <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
              href="completedquote"   
                icon="leaderboard"
              


title={localStorage.getItem("CustomerType") && localStorage.getItem("CustomerType")==="Dealer"?"Close Deals":"Closed Loans"}
               
 count={localStorage.getItem("VehicleCompletedQuoteLengthData")?localStorage.getItem("VehicleCompletedQuoteLengthData"):vehicleCompletedQuoteLengthDataNumber}
               
                color="success"
                percentage={{
                  color: "",
                  amount: "", 
                  label: "View",
                }}
              />
            </MDBox>
          </Grid>



          {localStorage.getItem("CustomerType") === "Credit Union" && (
  <Grid item xs={12} md={6} lg={3}>
    <MDBox mb={1.5}>
      <ComplexStatisticsCard
        href="javascript:void(0)"   
        icon="leaderboard"
        title="Survey Results"
        count={'coming soon'}
        color="primary"
        percentage={{
          color: "",
          amount: "", 
          label: "View",
        }}
      />
    </MDBox>
  </Grid>
)}




          <Grid item xs={12} md={6} lg={3}  style={{display:"none"}}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
              href={process.env.REACT_APP_CREDIT_UNION}
                color="success"
                icon="store"
                title="Submit Credit Union"
                count="0"
                percentage={{
                  color: "",
                  amount: "",
                  label: "View",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} style={{display:"none"}}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                href="test4"
                color="primary"
                icon="person_add"
                title="Loan Document"
                count="3"
                percentage={{
                  color: "",
                  amount: "",
                  label: "View",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5} >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12} >
              <MDBox mb={3} >
              
 <select className={'form-control'}

style={{
  "padding": "10px",
    "border": "1px solid #ccc",
    "borderRadius": "4px",
    "boxSizing": "borderBox",
    "width": "15%",
    "marginTop": "6px",
    "marginBottom": "16px",
    "resize": "vertical"}}

  id="granularity" value={localStorage.getItem('granularity')} onChange={handleGranularityChange}>
                <option value="yearly">Yearly</option>
                <option value="quarterly">Quarterly</option>
                <option value="monthly">Monthly</option>
                <option value="daily">Daily</option>
            </select>


                   <div style={{width:"100%",height:"60%"}}>
     

      <div className={'graphbox'}>
     
     <div className={'chartbox'} >
 <h4>Monthly Activity Chart</h4>
      <hr/>
      <MyBarChart  />

</div>

<div  className={'piebox'} >
       
        <PieChartComponent data={chartData} />

      </div>
      </div>

    </div>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4} style={{display:"none"}}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4} style={{display:"none"}}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox style={{display:"none"}}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer  />
    </DashboardLayout>
  );
}

export default Dashboard;
