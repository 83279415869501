import React, { useState,useEffect  } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box"; 
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import {  FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import authorsTableData from "layouts/customers/data/authorsTableData";
import projectsTableData from "layouts/customers/data/projectsTableData";
import Swal from "sweetalert";
import axios from "axios";
import md5 from 'md5';
function Customers() {
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();

  const [showForm, setShowForm] = useState(false);

  const [showFormCompany, setShowFormCompany] = useState(false);
  const [firstname, setfirstName] = useState('');
  const [lastname, setlastName] = useState('');
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [branch, setBranch] = useState('');
  const [password, setPassword] = useState('');
const [role, setRole] = useState('');
const [company, setCompany] = useState('');

const [companylist, setCompanyList] = useState([]);

//const [companynamedropdown, setcompanynameDropdown] = useState('');

const [companyNameDropdown, setCompanyNameDropdown] = useState(''); 
const [companyName, setCompanyName] = useState('');
const [customerType, setCustomerType] = useState('');
const [logo, setLogo] = useState('');

const [loading, setLoading] = useState(false);




 

 useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem('UserID');
    if (userIdFromLocalStorage) {

      setCompany(userIdFromLocalStorage);
    }





 if (localStorage.getItem('CustomerType') && localStorage.getItem('CustomerType') === "None") {
      const fetchCompanyList = async () => {
        try {
          const companylistd = `${process.env.REACT_APP_BASE_URL}authenticationAPI/GetCompnayName`;
          const response = await axios.get(companylistd);

          if (response.data) {

            console.log("companynamelist");


            console.log(response.data);


            setCompanyList(response.data);
          }
        } catch (error) {
          console.error('Error fetching company list:', error);
        }
      }

fetchCompanyList();


    }




  }, []);


  const handleButtonClick = () => {
    setShowForm(true); // Display the form when the button is clicked
  };

 const handleButtonClickCompany = () => {
    setShowFormCompany(true); // Display the form when the button is clicked
  };


   const handleCloseForm = () => {
    setShowForm(false); // Close the form when the close icon is clicked
    // Reset form fields
    setfirstName('');
    setlastName('');
    setEmail('');
    setTitle('');
    setBranch('');
    setPassword('');
    setRole('');
    setCompany('');
  };


  const handleCloseFormCompany = () => {
    setShowFormCompany(false); // Close the form when the close icon is clicked
    // Reset form fields
  //   setfirstName('');
  //   setlastName('');
  //   setEmail('');
  //   setTitle('');
  //   setBranch('');
  //   setPassword('');
  //   setRole('');
  //   setCompany('');
 };


   
 

  

  const sendEmail = (message) => {
    const MANDRILL_URL12 = process.env.REACT_APP_MANDRILL_API_URL;
    var MANDRILL_URL = MANDRILL_URL12.replace(/"/g, '');

    // Send email
    axios.post(MANDRILL_URL, message)
      .then(response => {
        console.log('Email sent successfully:', response.data);
        if(response.data[0].status) {
          // window.location.reload();
        }
      })
      .catch(error => {
        console.error('Error sending email:', error);
      });
  };





  const handleSubmitCompany = (event) => {
    event.preventDefault();
    // Handle form submission logic here
 if (!companyName ) 
 {
     Swal({
            text: "Please fill companyname field",
            icon: "error",
            
          })

      return;
    }

     if (!customerType) {
     
Swal({
            text: "Please fill customertype field",
            icon: "error",
            
          })

      return;
    }

     if (!logo) {

      Swal({
            text: 'Please fill logo field',
            icon: "error",
            
          })
      
      return;
    }

   


const customerData1 = {
      companyname:companyName,
      customertype:customerType,
      logo:logo
  };


 setLoading(true);

setTimeout(() => {
   axios 
      .post(`${process.env.REACT_APP_BASE_URL}authenticationAPI/InsertCompany`,
        
customerData1

      )
      .then((res) => {

        setLoading(false);
        if (res.data.status)
         {

             // const API_KEY12 = process.env.REACT_APP_EMAIL_API_KEY;
             // var API_KEY= API_KEY12.replace(/"/g, '');
            
            // Attach the PDF to the email
              // const MESSAGE = {
              //   "key": API_KEY,
              //   "message": {
              //     "text": "Member has been created by admin please approval Member",
              //     "subject": "Member Approval Notification",
              //     "from_email": "pifapp@myautoline.com",
              //     "to": [{
              //       "email": 'cresoluser@gmail.com', // Use the customer's email here
              //       "name": "PIF",
              //       "type": "to"
              //     }]
              //   }
              // };




              // sendEmail(MESSAGE);

//setTimeout(() => {


     Swal({
            text: "Company has been inserted",
            icon: "success",
            button: "OK",
          })  .then(() => {

          const url = new URL(window.location.href);
          const paramName = "token";
          url.searchParams.delete(paramName);
          window.location.href = url.toString();


          //       window.location.reload();
                   });

       // },3000)




}

})

  },4000)    
    // Reset form fields and hide the form
    //   setfirstName('');
    // setlastName('');
    // setEmail('');
    // setTitle('');
    // setBranch('');
    // setPassword('');
    // setRole('');
    // setCompany('');
    setShowFormCompany(false);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here




 if (!firstname ) {
     

Swal({
            text: "Please fill firstname field",
            icon: "error",
            
          })

      return;
    }

     if (!lastname) {
     
Swal({
            text: "Please fill lastname field",
            icon: "error",
            
          })

      return;
    }

     if (!email) {

      Swal({
            text: 'Please fill email field',
            icon: "error",
            
          })
      
      return;
    }

     if (!password) {


      swal({ 
        text:'Please fill password field',
        icon:"error",
      })
      return;
    }

 if (!branch ) {

      swal({ 
        text:'Please fill branch field',
      icon:"error",
      })     
return;
    }


 if (!title ) {
      swal({ 
        text:'Please fill title field',
        icon:"error",

})  
  return;
    }

     if (!role) {
      swal({ text:'Please select role field',
      icon:"error",
      })    
       return;
    }


if(localStorage.getItem('CustomerType') && localStorage.getItem('CustomerType')!=="None")
{

console.log("companynameDropdown");

  if (!company) {
      swal({ 
        text:'Please select company field',
        icon:"error",
      })
      return;
    }
}


else
{

console.log("companynameDropdown2");
console.log(companyNameDropdown);
if (!companyNameDropdown) {
      swal({ 
        text:'Please select company field2',
        icon:"error",
      })
      return;
    }

}

let customerData1;
if(localStorage.getItem('CustomerType') && localStorage.getItem('CustomerType')==="None")
{
console.log("test2");
 customerData1 = {
      branch:branch,
      first_name:firstname,
      last_name:lastname,
      email:email,
      password:md5(password),
       title:title,
      role:role,
      company:companyNameDropdown,
      test:"test1"
      
  };
}
else
{
console.log("test");
   customerData1 = {
      branch:branch,
      first_name:firstname,
      last_name:lastname,
      email:email,
      password:md5(password),
       title:title,
      role:role,
      company:company,
      test:"test2"
      
  };
}


   axios 
      .post(`${process.env.REACT_APP_BASE_URL}authenticationAPI/InsertCustomer`,
        
customerData1

      )
      .then((res) => {
        if (res.data.status)
         {

             const API_KEY12 = process.env.REACT_APP_EMAIL_API_KEY;
             var API_KEY= API_KEY12.replace(/"/g, '');
            
            // Attach the PDF to the email
              const MESSAGE = {
                "key": API_KEY,
                "message": {
                  "text": "Member has been created by admin please approval Member",
                  "subject": "Member Approval Notification",
                  "from_email": "pifapp@myautoline.com",
                  "to": [{
                    "email": 'cresoluser@gmail.com', // Use the customer's email here
                    "name": "PIF",
                    "type": "to"
                  }]
                }
              };




              sendEmail(MESSAGE);



     Swal({
            text: "Customer has been inserted",
            icon: "success",
            button: "OK",
          })  .then(() => {

          const url = new URL(window.location.href);
          const paramName = "token";
          url.searchParams.delete(paramName);
          window.location.href = url.toString();


          //       window.location.reload();
                   });




}})







    // Reset form fields and hide the form
      setfirstName('');
    setlastName('');
    setEmail('');
    setTitle('');
    setBranch('');
    setPassword('');
    setRole('');
    setCompany('');
    setShowForm(false);
  };


 const handleChangeCompany = (e) => {
    console.log('Selected value:', e.target.value);

    setCompanyNameDropdown(e.target.value);
    // setcompanynameDropdown(e.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div id="test" style={{ display: "none" }}>
        <h1>test</h1>
      </div>


<button style={{display: localStorage.getItem('CustomerType') && localStorage.getItem('CustomerType') === "None" ? "block" : "none",cursor: "pointer",padding: "10px", color:"white",borderRadius:"4px",backgroundColor: "rgb(0, 47, 108)", border: "1px solid rgb(0, 47, 108)" }} onClick={handleButtonClickCompany} >Add Company</button>

 {loading && (
        <>
          <div
            style={{
              color: "black",
              position: "fixed",
              top: "15%",
              left: "0",
              fontSize: "22px",
              width: "100%",
              height: "100%",
              zIndex: "9999",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Loading...
          </div>
          <div className="loader-overlay">
            <div className="spinner-square">
              <div className="square-1 square"></div>
              <div className="square-2 square"></div>
              <div className="square-3 square"></div>
            </div>
          </div>
        </>
      )}



<Dialog open={showFormCompany} onClose={handleCloseFormCompany}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Typography variant="h5" flexGrow={1}>
              Add Company Form
            </Typography>
            <IconButton onClick={handleCloseFormCompany} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitCompany}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Please Enter Company Name"
                  variant="outlined"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </Grid>

            
            
                 
                 

                 
              <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
                <InputLabel id="customer-type-label">Customer Type</InputLabel>
                <Select
                onChange={(e) => setCustomerType(e.target.value)}
                    labelId="customer-type-label"
                    value={customerType}
                    
                    label="Please Enter Customer Type"
                >
                    <MenuItem value="">Select Customer Type</MenuItem>
                    <MenuItem value="Credit Union">Credit Union</MenuItem>
                     <MenuItem value="Dealer" >Dealer</MenuItem>
                </Select>
            </FormControl>
        </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Please Enter Company Logo Url"
                  variant="outlined"
                  value={logo}
                  onChange={(e) => setLogo(e.target.value)}
                  required
                />
              </Grid>




            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFormCompany} variant="outlined" style={{"cursor": "pointer", "display": "block", "padding": "10px", "color": "white", "borderRadius": "4px", "backgroundColor": "rgb(0, 47, 108)", "border": "1px solid rgb(0, 47, 108)"}}>
            Cancel
          </Button>

          {!loading && (
          <Button onClick={handleSubmitCompany} variant="contained" color="primary" style={{"cursor": "pointer", "display": "block", "padding": "10px", "color": "white", "borderRadius": "4px", "backgroundColor": "rgb(0, 47, 108)", "border": "1px solid rgb(0, 47, 108)"}}>
            Submit
          </Button>
)}


        </DialogActions>
      </Dialog>




      <button style={{display: localStorage.getItem('CustomerType') && (localStorage.getItem('CustomerType') === "Dealer" || localStorage.getItem('CustomerType') === "None" || localStorage.getItem('CustomerType') === "Credit Union") && localStorage.getItem('Role') !== "TeamMember" ? "block" : "none",cursor: "pointer",padding: "10px", color:"white",borderRadius:"4px",backgroundColor: "rgb(0, 47, 108)", border: "1px solid rgb(0, 47, 108)" }} onClick={handleButtonClick} >Add Customer</button>

 <Dialog open={showForm} onClose={handleCloseForm}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Typography variant="h5" flexGrow={1}>
              Add Customer Form
            </Typography>
            <IconButton onClick={handleCloseForm} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  value={firstname}
                  onChange={(e) => setfirstName(e.target.value)}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  value={lastname}
                  onChange={(e) => setlastName(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Grid>
                <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  variant="outlined"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Grid>

                  <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={localStorage.getItem("CustomerType")==="Dealer"?"Dealer location":"Branch"}
                  variant="outlined"
                  type="text"
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                  required
                />
              </Grid>

                 

                   <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  variant="outlined"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </Grid>

              <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                onChange={(e) => setRole(e.target.value)}
                    labelId="role-label"
                    value={role}
                    
                    label="Role"
                >
                    <MenuItem value="">Select Role</MenuItem>
                    <MenuItem value="TeamMember">Team Member</MenuItem>
                     <MenuItem value="Admin" style={{display:localStorage.getItem("CustomerType") && localStorage.getItem("CustomerType")=='None'?"block":"none"}}>Admin</MenuItem>
                </Select>
            </FormControl>
        </Grid>



     <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
                <InputLabel id="company-name-label">Company Name</InputLabel>
           
<Select
          onChange={handleChangeCompany}
          labelId="company-name-label"
          value={companyNameDropdown || ''}
          label="Company Name"
        >
                    
               



 


          <MenuItem value="">Select Company</MenuItem>
          

 {companylist.map((company12, index) => {
            const userMemberIddata = company12.UserID || '';
            const companyNamedata = company12.CompanyName || '';
            console.log('Company Data:', company12, 'UserMemberID:', userMemberIddata, 'CompanyName:', companyNamedata);
            return (
              <MenuItem key={`${userMemberIddata}-${index}`} value={userMemberIddata}>
                {companyNamedata}
              </MenuItem>
            );
          })}


                </Select>
            </FormControl>
        </Grid>




            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm} variant="outlined" style={{"cursor": "pointer", "display": "block", "padding": "10px", "color": "white", "borderRadius": "4px", "backgroundColor": "rgb(0, 47, 108)", "border": "1px solid rgb(0, 47, 108)"}}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary" style={{"cursor": "pointer", "display": "block", "padding": "10px", "color": "white", "borderRadius": "4px", "backgroundColor": "rgb(0, 47, 108)", "border": "1px solid rgb(0, 47, 108)"}}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>


      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                borderRadius="lg"
                coloredShadow="info"
                style={{ backgroundColor: "rgb(0, 47, 108)", border: "1px solid rgb(0, 47, 108)" }}
              >
                <MDTypography variant="h6" color="white">


{
window.location.pathname === '/customer' && localStorage.getItem("CustomerType") === 'Dealer'
    ? 'Dealer Roster'
    : (
      window.location.pathname === '/customer'
        ? 'CU Roster'
        : (
          window.location.pathname === '/quote' && localStorage.getItem("CustomerType") === 'Dealer'
            ? 'Pending Deals'
            : (
              window.location.pathname === '/quote'
                ? 'Pending Loans'
                : (
                  window.location.pathname === '/completedquote' && localStorage.getItem("CustomerType") === 'Dealer'
                    ? 'Closed Deals'
                    : (
                      window.location.pathname === '/completedquote'
                        ? 'Closed Loans'
                        : window.location.pathname
                    )
                )
            )
        )
    )

}

                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={true}
                  pagination={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} style={{ display: "none" }}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Customers;
