/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function SidenavCollapse({ icon, name, active, routes, ...rest }) {



  const isImageIcon = typeof icon === 'string' && icon.startsWith('http');

let iconUrl;

if (typeof icon === 'string') {
 

 iconUrl = active 
    ? icon.split('||')[1].trim() 
    : icon.split('||')[0].trim();
 
 }   


  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

// Define the class based on the active prop
  const boxClass = active ? "MuiBox-root css-1x3v3vy" : "MuiBox-root css-mvs481";

// alert(active);
// alert(routes);
  return (

    <ListItem 


style={name === 'Quote' && localStorage.getItem('CustomerType') === 'Dealer' ? { display: 'none' } : {}}

    component="li">
      <MDBox



        {...rest}
        sx={(theme) =>
          collapseItem(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            sidenavColor,
          })
        }
      >


     {isImageIcon ? (
          <img src={iconUrl} alt={`${name} Icon`} style={{ width: 24, height: 24 }} />
        ) : (
          <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
        )}


        <ListItemIcon style={{display:"none"}}
          sx={(theme) =>
            collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
          }
        >
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
        


          ) :
           (
            icon
        
          )}
        </ListItemIcon>

        
        <ListItemText


 primary={
  name === 'Customer' && localStorage.getItem("CustomerType") === 'Dealer'
    ? 'Dealer Roster'
    : (name === 'Customer' && localStorage.getItem("CustomerType") === 'None'
        ? 'Roster'
    
    : (name === 'Customer' && localStorage.getItem("CustomerType") !== 'None' && localStorage.getItem("CustomerType") === 'Dealer'
        ? 'CU Roster'
        : (name === 'Quote' && localStorage.getItem("CustomerType") === 'Dealer'
            ? 'Pending Deals'
            : (name === 'Quote'
                ? 'Pending Loans'
                : (name === 'CompletedQuote' && localStorage.getItem("CustomerType") === 'Dealer'
                    ? 'Closed Deals'
                    : (name === 'CompletedQuote'
                        ? 'Closed Loans'
                        : name))))))
}



          sx={(theme) =>
            collapseText(theme, {
              miniSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
            })
          }
        />


      </MDBox>
    </ListItem>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
