import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Swal from "sweetalert";
import md5 from 'md5';
import axios from 'axios';


const DialogResetComponent = ({ open,selectedMemberId }) => {


  console.log("selectedMemberId");
  
console.log(selectedMemberId);
  
  const [password2, setPassword2] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');

  const handleCloseForm2 = () => {
    // Reset form fields and close the dialog
    setPassword2('');
    setConfirmPassword('');
    window.location.reload(); // Assuming handleClose is a prop function passed from parent
  };

   const handleSubmit2 = () => {


if(password2=="")
{

 Swal({
             text: "Please enter password field",
             icon: "error",

 })
return false;
}


if(confirmpassword=="")
{

 Swal({
             text: "Please enter confirm password field",
             icon: "error",

 })

return false;
}


if(password2!==confirmpassword)
{

 Swal({
             text: "password and confirm password showuld be same ",
             icon: "error",

 })

return false;
}






 const memberpasswordreseturl = process.env.REACT_APP_BASE_URL + 'authenticationAPI/updatememberpassword';
    const data = {
      member_id: selectedMemberId,
      password: md5(password2),
    };
console.log(data);
    axios.post(memberpasswordreseturl, data)
      .then(response => {
        console.log("API Response:", response.data);

if(response.status)
{

Swal({
             text: "password has been updated",
             icon: "success",

 })


setPassword2('');
    setConfirmPassword('');
     window.location.reload();

localStorage.setItem('TempPasswordStatus',0)

}

        // Show success message or handle response as needed
      })
      .catch(error => {
        console.error("API Error:", error);
        // Handle error scenarios
      });





    // Reset form fields and close the dialog
     // Assuming handleClose is a prop function passed from parent
  };

  useEffect(() => {



    // Effect to handle cleanup or additional logic on prop changes
    // You can add logic here if needed when `open` changes
  }, [open,selectedMemberId]); // Ensure to include `open` as a dependency if you use it inside useEffect

  return (
    <Dialog open={open} onClose={handleCloseForm2}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Typography variant="h5" flexGrow={1}>
            Password Reset {selectedMemberId}
          </Typography>
          <IconButton aria-label="close" onClick={handleCloseForm2}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Password"
                variant="outlined"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                variant="outlined"
                value={confirmpassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseForm2} variant="outlined" style={{ cursor: "pointer", padding: "10px", color: "white", borderRadius: "4px", backgroundColor: "rgb(0, 47, 108)", border: "1px solid rgb(0, 47, 108)" }}>
          Cancel
        </Button>

        <Button onClick={handleSubmit2} variant="outlined" style={{ cursor: "pointer", padding: "10px", color: "white", borderRadius: "4px", backgroundColor: "rgb(0, 47, 108)", border: "1px solid rgb(0, 47, 108)" }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogResetComponent.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default DialogResetComponent;
