// SignaturePad.js
import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
 // Import the CSS file

const SignaturePad = ({ setSignature }) => {
  const sigCanvas = useRef({});


  const clear = () => sigCanvas.current.clear();

  const save = () => {
    if (sigCanvas.current.isEmpty()) {
      alert("Please provide a signature first.");
    } else {

      setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
localStorage.setItem("signedattach",sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
     
    }
  };

  return (
    <div className="signature-pad-container">
     
 <div className="signature-pad-buttons">
        <button onClick={clear}>Clear</button>
        <button onClick={save}>Save</button>
      </div>

      <div className="signature-pad" >
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          canvasProps={{ width: 100, height: 50, className: 'sigCanvas' }}
        />
      </div>


      


       
      
    </div>
  );
};

export default SignaturePad;
